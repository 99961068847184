import React from "react";
import { Scoped, k } from "kremling";

export default class UploadConversionsHeader extends React.Component {
  render() {
    return (
      <Scoped css={css}>
        <div className="cps-card__header">
          <span className="header-title">Upload File</span>
          <div className="header-right-container">
            <a
              onClick={this.props.onCompleted}
              className="cps-icon cps-icon-close"
            />
          </div>
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .cps-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-title {
    font-size: 21px;
  }

  .header-right-container {
    display: flex;
    align-items: center;
  }

  .vertical-line {
    height: 25px;
    border-left: 1px solid #979797;
    margin-left: 12px;
  }

  .cps-icon-close {
    position: relative;
    right: -8px;
    text-decoration: none;
    color: #777777;
  }
`;
