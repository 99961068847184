import React from "react";
import CanopySelect from "cpr-select";
import Dropzone from "react-dropzone";
import { CprButton } from "canopy-styleguide!sofe";
import { Scoped, k } from "kremling";
import Cancelable from "react-disposable-decorator";
import { asyncStacktrace } from "auto-trace";
import { successToast, warningToast } from "toast-service!sofe";
import { find, isEmpty, get } from "lodash";

import UploadConversionsHeader from "./upload-conversions-header.component.js";
import {
  getExtensionsList,
  getVendorsList,
  uploadConversionFile,
} from "../../sme-qa-ui.resource.js";
import { Loader } from "../../ui/loader.component";

@Cancelable
export default class UploadConversions extends React.Component {
  state = {
    selectedVendor: null,
    loading: false,
    vendors: [],
    isValid: null,
    file: null,
    extensionsList: [],
    vendorExtensions: [],
  };

  componentDidMount() {
    this.getExtensions();
    this.getVendors();
  }

  fileUploadContents = () => {
    const { file, vendorExtensions } = this.state;

    if (file && this.state.isValid) {
      return (
        <div className="conversions-file-upload">
          <img
            src="https://cdn.canopytax.com/static/empty-states/es_files_add.svg"
            height={72}
          />
          <div
            style={{
              color: "rgb(82, 85, 90)",
              fontSize: "24px",
              fontWeight: "bold",
              padding: "16px 5px 0",
            }}
          >
            {file.name}
          </div>
          <div
            style={{
              color: "rgb(119, 119, 119)",
              fontSize: "16px",
              fontWeight: "normal",
              paddingBottom: "24px",
            }}
          >
            {file.size < 5000
              ? `${(file.size / 1000).toFixed(2)}KB`
              : `${(file.size / 1000000).toFixed(2)}MB`}
          </div>
          <div style={{ flexDirection: "row" }}>
            {!this.state.loading ? (
              <>
                <CprButton
                  actionType="secondary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.setState({ isValid: null, file: null });
                  }}
                >
                  Remove file
                </CprButton>
                <CprButton
                  actionType="primary"
                  style={{ cursor: "pointer" }}
                  onClick={this.upload}
                >
                  Upload File
                </CprButton>
              </>
            ) : (
              <Loader color="rainbow" />
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="conversions-file-upload">
        <img
          src="https://cdn.canopytax.com/static/empty-states/es_files_add.svg"
          height={72}
        />
        <div
          style={{
            color: "rgb(82, 85, 90)",
            fontSize: "24px",
            fontWeight: "bold",
            padding: "16px 5px 0",
          }}
        >
          Upload File
        </div>
        <div
          style={{
            color: "rgb(119, 119, 119)",
            fontSize: "16px",
            fontWeight: "normal",
          }}
        >
          Drag and drop file here
        </div>
        {!isEmpty(vendorExtensions) && vendorExtensions[0] !== ".*" && (
          <div
            style={{
              color: "rgb(119, 119, 119)",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            ({vendorExtensions.join(", ")})
          </div>
        )}
        <CprButton
          actionType="primary"
          disabled={!this.state.selectedVendor}
          style={{ cursor: "pointer", marginTop: "24px" }}
        >
          Select File
        </CprButton>
      </div>
    );
  };

  getExtensions = () => {
    this.props.cancelWhenUnmounted(
      getExtensionsList().subscribe(extensionsList => {
        this.setState({
          extensionsList,
        });
      }, asyncStacktrace())
    );
  };

  getVendors = () => {
    const { revisionId, versionId } = this.props;
    this.setState({ loading: true });
    this.props.cancelWhenUnmounted(
      getVendorsList(versionId, revisionId).subscribe(vendorsList => {
        const vendors = vendorsList.map(vendor => {
          return { value: vendor.name, key: vendor.id };
        });

        this.setState({
          vendors,
          loading: false,
        });
      }, asyncStacktrace())
    );
  };

  handleDrop = file => {
    this.setState({
      isValid: !!file.length,
      file: file[0],
    });
  };

  upload = () => {
    const { file, isValid, selectedVendor } = this.state;
    const { versionId, clientId, revisionId, engagementId } = this.props;

    if (file && isValid) {
      this.setState({ loading: true });
      this.props.cancelWhenUnmounted(
        uploadConversionFile(
          versionId,
          revisionId,
          clientId,
          engagementId,
          selectedVendor,
          file
        ).subscribe(
          () => {
            successToast(`Successfully uploaded file!`);
            window.location.reload();
          },
          () => {
            this.setState({
              loading: false,
            });
            warningToast(`An unknown error occurred. Unable to upload file`);
            asyncStacktrace();
          }
        )
      );
    }
  };

  render() {
    const {
      selectedVendor,
      file,
      extensionsList,
      vendorExtensions,
      vendors,
    } = this.state;
    return (
      <Scoped css={css}>
        <div className="cps-card">
          <UploadConversionsHeader onCompleted={this.props.onCompleted} />
          <div className="vendor-select">
            <div style={{ paddingBottom: "5px" }}>Select a vendor</div>
            <CanopySelect
              options={vendors}
              onChange={value => {
                this.setState(() => {
                  const vendor = find(vendors, vendor => vendor.key === value);
                  const extensionObj = find(extensionsList, ext => {
                    return ext.vendor === vendor.value;
                  });

                  return {
                    file: null,
                    isValid: null,
                    selectedVendor: value,
                    vendorExtensions: get(extensionObj, "extensions", []),
                  };
                });
              }}
              selected={selectedVendor}
              placeholder="Select a vendor"
            />
          </div>
          {selectedVendor && !file ? (
            <Dropzone
              onDrop={this.handleDrop}
              multiple={false}
              accept={
                !isEmpty(vendorExtensions) && vendorExtensions[0] !== ".*"
                  ? vendorExtensions
                  : ""
              }
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} style={{ outline: "none" }}>
                  <input {...getInputProps()} />
                  {this.fileUploadContents()}
                </div>
              )}
            </Dropzone>
          ) : (
            this.fileUploadContents()
          )}
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .cps-card {
    width: 46rem;
    border-radius: 5px;
  }

  .vendor-select {
    width: 40rem;
    margin: 2rem 3rem;
    font-size: 14px;
    color: rgb(82, 85, 90);
  }

  .conversions-file-upload {
    width: 40rem;
    margin: 2rem 3rem;
    height: 27rem;
    border: 1px solid rgb(233, 233, 233);
    background: rgb(244, 245, 248);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
