import React from "react";
import PropTypes from "prop-types";
import { Scoped, a, t, k } from "kremling";
import { CprIcon } from "canopy-styleguide!sofe";
import Cancelable from "react-disposable-decorator";
import { get } from "lodash";

@Cancelable
export default class ManageTestsErrorsFormTable extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    expandAll: PropTypes.bool,
  };

  state = {
    expandedRow: {},
    tableOpen: this.props.expandAll,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.expandAll !== this.props.expandAll) {
      this.setState({ tableOpen: this.props.expandAll });
    }
  }

  displayValue = value => {
    if (value === null || value[0] === null) {
      return "(null)";
    } else if (value === "") {
      return "(blank)";
    } else if (value === true || value === false) {
      return value.toString();
    } else if (value.constructor === Object) {
      return value.name;
    } else {
      return JSON.stringify(value);
    }
  };

  renderErrorTable = data => {
    return (
      <div className="error-table">
        <div className="error-header-row">
          <div className="error-header-col error-col-type">Type</div>
          <div className="error-header-col error-col-other">Expected</div>
          <div className="error-header-col error-col-other">Actual</div>
          <div style={{ width: "24px" }} />
        </div>
        {data.map((row, i) => {
          if (!row.fieldName) return "";
          let expanded = false;

          if (this.state.expandedRow === row) expanded = true;

          const fieldnamesArray = row.fieldName.split("/");
          const lastField = fieldnamesArray.pop();
          const includesAddendums = fieldnamesArray.includes("addendums");
          const multiformIndex = fieldnamesArray.findIndex(
            val => val === "answersList"
          );
          let multiForm = "";
          let fieldName = "";
          let addendumName = "";
          let expectedName = get(row, "expected", "");
          let actualName = get(row, "actual", "");

          if (multiformIndex > -1) {
            multiForm = `Multiform ${Number(
              fieldnamesArray[multiformIndex + 1]
            ) + 1} - `;
          }

          if (expectedName && expectedName.constructor === Object)
            expectedName = `${expectedName.name || ""}`;

          if (actualName && actualName.constructor === Object)
            actualName = `${actualName.name || ""}`;

          if (multiForm && expectedName)
            expectedName = `${multiForm}${expectedName}`;

          if (multiForm && actualName) actualName = `${multiForm}${actualName}`;

          if (includesAddendums && !isNaN(Number(lastField))) {
            if (!actualName && !expectedName) {
              addendumName = `${lastField}(addendum)`;
            } else {
              fieldName = `${expectedName || actualName} (Addendum)`;
            }
          } else if (lastField.includes(".")) {
            const formAndField = lastField.split(".");
            fieldName = `${formAndField[1]} (${formAndField[0]})`;
          } else if (includesAddendums) {
            addendumName = `${lastField}(addendum)`;
          } else if (
            fieldnamesArray.length <= 1 &&
            lastField &&
            fieldnamesArray[0] === ""
          ) {
            addendumName = `${lastField}(addendum)`;
          } else {
            fieldName = lastField;
          }

          const showArrow =
            (fieldName && fieldName.length > 49) ||
            (addendumName && addendumName.length > 49) ||
            (expectedName && expectedName.length > 23) ||
            (actualName && actualName.length > 22);

          return (
            <div
              className="error-data-row"
              onClick={() => {
                this.setState(() => {
                  if (expanded) return { expandedRow: {} };
                  return { expandedRow: row };
                });
              }}
              key={JSON.stringify(row) + i}
            >
              <div
                className={`${t(
                  "expanded",
                  "error-data-col",
                  expanded && showArrow
                )} error-col-type`}
              >
                {fieldName || addendumName}
              </div>
              <div
                className={`${t(
                  "expanded",
                  "error-data-col",
                  expanded && showArrow
                )} error-col-other expected`}
              >
                {this.displayValue(expectedName)}
              </div>
              <div
                className={`${t(
                  "expanded",
                  "error-data-col",
                  expanded && showArrow
                )} error-col-other actual`}
              >
                {this.displayValue(actualName)}
              </div>
              {showArrow ? (
                <CprIcon
                  name={expanded ? "caret-small-up" : "caret-small-down"}
                  style={{ padding: "12px 4px 0px 8px" }}
                />
              ) : (
                <div style={{ width: "38px" }} />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { form } = this.props;
    const { tableOpen } = this.state;
    const formName = Object.keys(form)[0];
    const formData = form[formName];

    return (
      <Scoped css={css}>
        <div
          className={a("form-list-title").m("form-list-title-open", tableOpen)}
          onClick={() => {
            this.setState(prevState => {
              return { tableOpen: !prevState.tableOpen };
            });
          }}
        >
          <div className="form-name">
            <CprIcon
              name={tableOpen ? "caret-small-down" : "caret-small-right"}
              style={{ alignItems: "center" }}
            />
            {formName}
          </div>
          <div className="form-failed-test-count">{formData.length}</div>
        </div>
        {tableOpen && this.renderErrorTable(formData)}
      </Scoped>
    );
  }
}

const css = k`
  .form-list-title {
    display: flex;
    font-size: 16px;
    font-weight: normal;
    justify-content: space-between;
    padding: 8px 90px 8px 16px;
  }

  .form-list-title-open {
    font-weight: bold;
  }

  .form-list-title:hover {
    background-color: var(--cps-color-silver);
  }

  .error-table {
    border-radius: 2px;
    border: 1px solid var(--cps-color-athens);
    margin: 4px 10px;
  }

  .error-header-row {
    display: flex;
    background-color: var(--cps-color-blue-smoke);
    border-bottom: 1px solid var(--cps-color-athens);
    text-align: left;
    height: 32px;
    padding-left: 8px;
    justify-content: space-between;
  }

  .error-header-col {
    font-weight: 600;
    font-size: 14px;
    padding-top: 8px;
  }

  .error-col-type {
    width: 50%;
  }

  .error-col-other {
    width: 25%;
  }

  .error-data-row {
    display: flex;
    justify-content: space-between;
    border-right: 1px solid var(--cps-color-ash);
    border-bottom: 1px solid var(--cps-color-athens);
  }

  .error-data-row .error-data-col:nth-last-child() {
    border-right: none;
  }

  .error-data-col {
    text-align: left;
    padding-left: 8px;
    line-height: 48px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .expanded {
    text-align: left;
    padding: 10px 0 10px 8px;
    word-break: break-word;
  }

  .expanded:nth-last-child(-n+3), 
    .error-data-col:nth-last-child(-n+3) {
    border-left: 1px solid var(--cps-color-athens);
  }

  .error-table .error-data-row:nth-child(odd) {
    background-color: var(--cps-color-blue-smoke);
  }

  .error-table .error-data-row:last-child {
    border-bottom: none;
  }

  .error-table .error-data-row:nth-child(even) {
    background-color: var(--cps-color-bumble);
  }
`;
