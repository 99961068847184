import React from "react";
import { Scoped, always, k } from "kremling";
import { findIndex, get, isEmpty, find } from "lodash";
import { catchSyncStacktrace, asyncStacktrace } from "auto-trace";
import { infoToast } from "toast-service!sofe";
import Cancelable from "react-disposable-decorator";
import ErrorBoundary from "canopy-react-error-boundary";

import { editFolder, editTest } from "../../sme-qa-ui.resource.js";
import ManageTestsDirectoryHeader from "./manage-tests-directory-header.component.js";
import ManageTestsDirectoryBody from "./manage-tests-directory-body.component.js";
import ManageTestsErrors from "./manage-tests-errors.component.js";
import ManageTestsOptions from "./manage-tests-options.component.js";
import ManageTestsMove from "./manage-tests-move.component.js";
import DragAndDropWrapper from "../../common/drag-and-drop-wrapper.component.js";
import DocumentsDragLayer from "../../common/documents-drag-layer.component.js";
import Search from "../../ui/search.component.js";
import Breadcrumbs from "../../common/breadcrumbs.component.js";

export class ManageTests extends React.Component {
  constructor(props) {
    super(props);

    this.counter = 0;

    this.state = {
      areAllItemsSelected: false,
      currentRevision: null,
      draggedItems: [],
      dropZone: null,
      filter: { name: "", date: "", status: "" },
      moveList: [],
      selectedTest: null,
      showCalculations: false,
      showMoveInbox: false,
    };
  }

  render() {
    const { versionId, revisionId } = this.props;
    const {
      areAllItemsSelected,
      currentRevision,
      draggedItems,
      filter,
      moveList,
      selectedTest,
      showCalculations,
      showMoveInbox,
    } = this.state;

    return (
      <Scoped css={css}>
        <div className="cps-card-main">
          <div className="cps-card-main-right">
            <div className="sub-section">
              <Breadcrumbs
                breadcrumbs={this.props.breadcrumbs}
                callGetDirectory={this.props.callGetDirectory}
                callGetRootDirectory={this.props.callGetRootDirectory}
                maxLength={5}
              />
              <Search
                search={this.props.search}
                editSearch={this.props.editSearch}
                clearSearch={this.props.clearSearch}
              />
              <a
                onClick={this.props.createFolder}
                className="cps-icon cps-icon-new-folder"
              />
            </div>
            <ManageTestsOptions
              toggleFailedTests={this.props.toggleFailedTests}
              showOnlyFailedTests={this.props.showOnlyFailedTests}
              search={this.props.search}
            />
            <div className="folders-and-calculations">
              <div
                className={always("cps-flex-table").toggle(
                  "table-sm",
                  "table-lrg",
                  showMoveInbox || showCalculations
                )}
              >
                <ManageTestsDirectoryHeader
                  filter={filter}
                  handleFilterChange={this.handleFilterChange}
                  sidePanelOpen={showMoveInbox || showCalculations}
                  handleSortClick={this.props.handleSortClick}
                />
                <DragAndDropWrapper
                  isDropZone={true}
                  dragEnter={this.dragEnter}
                  dragLeave={this.dragLeave}
                >
                  <ManageTestsDirectoryBody
                    isRootFolder={isEmpty(this.props.breadcrumbs)}
                    parentId={this.props.parentId}
                    showDirectory={this.props.showDirectory}
                    callGetDirectory={this.props.callGetDirectory}
                    directory={
                      this.props.directory
                        ? this.props.directory.filter(i => {
                            return !~findIndex(moveList, m => m.id === i.id);
                          })
                        : []
                    }
                    newFolder={this.props.newFolder}
                    addItem={this.props.addItem}
                    editItem={this.props.editItem}
                    deleteItem={this.props.deleteItem}
                    cancelFolder={this.props.cancelFolder}
                    versionId={versionId}
                    revisionId={revisionId}
                    addSelectedTest={this.addSelectedTest}
                    searchResults={this.props.searchResults}
                    filter={filter}
                    handleMoveClick={this.handleMoveClick}
                    dragEnter={this.dragEnter}
                    dragLeave={this.dragLeave}
                    sidePanelOpen={showMoveInbox || showCalculations}
                    draggedItems={draggedItems}
                    refetchSearch={this.props.refetchSearch}
                    certifiable={this.props.certifiable}
                    taskId={this.props.taskId}
                    parentId={this.props.parentId}
                    clientId={this.props.clientId}
                    engagementId={this.props.engagementId}
                    onCompleted={this.props.onCompleted}
                  />
                </DragAndDropWrapper>
              </div>
              {showCalculations && (
                <ManageTestsErrors
                  hideCalculations={this.hideCalculations}
                  selectedTest={selectedTest}
                  versionId={versionId}
                  revisionId={revisionId}
                />
              )}
              {showMoveInbox && (
                <ManageTestsMove
                  moveList={moveList}
                  removeFromMoveList={this.removeFromMoveList}
                  closeMoveInbox={this.closeMoveInbox}
                  selectMoveItem={this.selectMoveItem}
                  dragStart={this.dragStart}
                  dragEnd={this.dragEnd}
                  areAllItemsSelected={areAllItemsSelected}
                  handleSelectAllClick={this.handleSelectAllClick}
                  draggedItems={draggedItems}
                />
              )}
            </div>
            <DocumentsDragLayer
              docDragging={draggedItems.length}
              selectedDocs={draggedItems}
            />
          </div>
        </div>
      </Scoped>
    );
  }

  handleSelectAllClick = () => {
    this.setState(prevState => {
      const areAllItemsSelected = !prevState.areAllItemsSelected;
      const moveList = prevState.moveList.map(i => ({
        ...i,
        isSelected: areAllItemsSelected,
      }));
      return { moveList, areAllItemsSelected };
    });
  };

  addSelectedTest = test => {
    this.setState({
      showCalculations: true,
      selectedTest: test,
      showMoveInbox: false,
    });
  };

  hideCalculations = () => {
    this.setState({ showCalculations: false });
  };

  handleFilterChange = (evt, prop) => {
    const value = evt.target.value;
    this.setState(prevState => {
      return { filter: { ...prevState.filter, [prop]: value } };
    });
  };

  handleMoveClick = item => {
    const length = this.state.moveList.filter(i => i.id === item.id).length;
    this.setState(prevState => {
      return {
        showMoveInbox: true,
        showCalculations: false,
        moveList: length
          ? prevState.moveList
          : [
              ...prevState.moveList,
              { ...item, isSelected: false, parentId: this.props.parentId },
            ],
      };
    });
  };

  removeFromMoveList = item => {
    this.setState(prevState => {
      return { moveList: prevState.moveList.filter(i => i.id !== item.id) };
    });
  };

  closeMoveInbox = () => {
    this.setState({ showMoveInbox: false });
  };

  dragStart = item => {
    this.setState(prevState => {
      const draggedItems = item.isSelected
        ? prevState.moveList.filter(i => i.isSelected)
        : [item];
      return { draggedItems };
    });
  };

  dragEnd = () => {
    const { draggedItems, dropZone } = this.state;
    if (dropZone !== null) {
      const moveFolders = draggedItems.filter(i => !i.isTest);
      const moveTests = draggedItems.filter(i => i.isTest);
      moveFolders.forEach(folder => {
        this.handleMoveFolder(folder, dropZone);
      });
      moveTests.forEach(test => {
        this.handleMoveTest(test, dropZone);
      });
    }
    this.counter = 0;
    this.setState({ draggedItems: [], dropZone: null });
  };

  dragEnter = item => {
    this.counter++;
    const id = item ? item.id : this.props.parentId;
    this.setState({ dropZone: id });
  };

  dragLeave = () => {
    this.counter--;
    if (this.counter === 0) {
      this.setState({ dropZone: null });
    }
  };

  selectMoveItem = item => {
    this.setState(prevState => {
      let moveList = prevState.moveList.slice();
      const itemIndex = findIndex(moveList, i => i.id === item.id);
      moveList.splice(itemIndex, 1, { ...item, isSelected: !item.isSelected });
      const areAllItemsSelected =
        moveList.length === moveList.filter(i => i.isSelected).length;
      return { moveList, areAllItemsSelected };
    });
  };

  handleMoveFolder = (folder, dropZone) => {
    const { parentId, versionId, revisionId } = this.props;

    if ((folder.parentId || dropZone) && folder.parentId !== dropZone) {
      this.props.cancelWhenUnmounted(
        editFolder(versionId, revisionId, folder, dropZone).subscribe(
          newFolder => {
            newFolder.parentId = get(newFolder.path[1], "id", "");
            if (newFolder.parentId === parentId) {
              this.props.addItem(newFolder);
            } else if (
              findIndex(this.props.directory, i => i.id === newFolder.id) > -1
            ) {
              this.props.deleteItem(newFolder);
            }
            this.setState(prevState => {
              const moveList = prevState.moveList.filter(
                i => i.id !== newFolder.id
              );
              return {
                moveList,
                areAllItemsSelected:
                  !isEmpty(moveList) &&
                  moveList.length === moveList.filter(i => i.isSelected).length,
              };
            });
          },
          err => {
            if (get(err, "data.errors.httpStatusCode") === 409) {
              const messageStr = get(err, "data.errors.message");
              const message = messageStr.slice(messageStr.indexOf("|") + 1);
              infoToast(message);
            } else {
              catchSyncStacktrace();
            }
          }
        )
      );
    } else {
      infoToast("Must move to a new folder");
    }
  };

  handleMoveTest = (test, dropZone) => {
    const {
      clientId,
      parentId,
      engagementId,
      versionId,
      revisionId,
    } = this.props;
    if (!dropZone) {
      infoToast("Can't place test at root level");
    } else if (test.folderParentId === dropZone) {
      infoToast("Must move test to a new folder");
    } else {
      this.props.cancelWhenUnmounted(
        editTest(
          versionId,
          revisionId,
          test,
          dropZone,
          engagementId,
          clientId
        ).subscribe(
          test => {
            if (test.folderParentId === parentId) {
              this.props.addItem(test);
            } else if (
              findIndex(this.props.directory, i => i.id === test.id) > -1
            ) {
              this.props.deleteItem(test);
            }
            this.setState(prevState => {
              const moveList = prevState.moveList.filter(i => i.id !== test.id);
              return {
                moveList,
                areAllItemsSelected:
                  !isEmpty(moveList) &&
                  moveList.length === moveList.filter(i => i.isSelected).length,
              };
            });
          },
          err => {
            if (get(err, "data.errors.httpStatusCode") === 409) {
              const messageStr = get(err, "data.errors.message");
              const message = messageStr.slice(messageStr.indexOf("|") + 1);
              infoToast(message);
            } else {
              catchSyncStacktrace();
            }
          }
        )
      );
    }
  };
}

const css = k`
  .cps-card-main {
    display: flex;
  }

  .cps-card-main-right {
    width: 100%;
  }

  .folders-and-calculations {
    display: flex;
  }

  .table-sm {
    width: 564px;
  }

  .table-lrg {
    width: 100%;
  }

  .sub-section {
    display: flex;
    border-bottom: 0.1rem solid var(--cps-color-silver);
    align-items: center;
  }
`;

export default Cancelable(
  ErrorBoundary({ featureName: "sme-qa-ui:manageTests" })(ManageTests)
);
