import React from "react";
import { Scoped, k } from "kremling";
import { createDisposableModal } from "react-disposable-modal";
import { catchSyncStacktrace, asyncStacktrace } from "auto-trace";
import { infoToast } from "toast-service!sofe";
import Cancelable from "react-disposable-decorator";

import TestModal from "../test-modal/test-modal.component.js";
import { getVersions } from "../sme-qa-ui.resource";

@Cancelable
export default class TestMenu extends React.Component {
  state = {
    persistingActiveTab: null,
    openMenu: false,
    openModal: false,
    persistedLocationId: null,
    persistingRevision: null,
    persitingVersion: null,
    versions: [],
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      getVersions().subscribe(versions => {
        this.setState({
          versions,
        });
      }, asyncStacktrace())
    );
  }

  setPersistingActiveTab = persistingActiveTab => {
    this.setState({ persistingActiveTab });
  };

  openMenu = () => {
    this.setState({ openMenu: true });
  };

  closeMenu = () => {
    this.setState({ openMenu: false });
  };

  openModal = (type, callback) => {
    this.setState({ openModal: true });
    const {
      versionId,
      revisionId,
      engagementId,
      clientId,
      folderIds,
      taxFormName,
      taxFormType,
      taskId,
    } = this.props;
    const {
      persistingActiveTab,
      persistedLocationId,
      persistingRevision,
      persitingVersion,
      versions,
    } = this.state;

    if (taxFormType) {
      const subscription = createDisposableModal(TestModal, {
        persistingActiveTab,
        setPersistingActiveTab: this.setPersistingActiveTab,
        type,
        versionId,
        revisionId,
        engagementId,
        clientId,
        folderIds,
        taxFormName,
        taxFormType,
        taskId,
        persistedLocationId,
        persistingRevision,
        persitingVersion,
        setPersistingLocationId: this.setPersistingLocationId,
        setPersistingVersionAndRevision: this.setPersistingVersionAndRevision,
        versions,
      }).subscribe(
        data => {
          callback(data);
        },
        err => {
          catchSyncStacktrace(err);
        },
        () => {
          this.setState({ openMenu: false, openModal: false });
        }
      );
    } else {
      infoToast(
        "Please select a product for this engagement before using the sme qa automation tool.",
        "Edit engagement",
        () => {
          this.setState({ openMenu: false, openModal: false });
          window.location.hash = `/taxes/client/${clientId}/engagements/${engagementId}/details`;
        }
      );
    }
  };

  setPersistingLocationId = persistedLocationId => {
    this.setState({ persistedLocationId });
  };

  setPersistingVersionAndRevision = (persitingVersion, persistingRevision) => {
    this.setState({ persitingVersion, persistingRevision });
  };

  render() {
    return (
      <Scoped css={css} namespace="sme-qa-ui-test-menu">
        {!this.state.openModal &&
          (this.state.openMenu ? (
            <div className="test-menu">
              <div className="close-menu-container">
                <span
                  className="cps-icon cps-icon-close close-offset"
                  onClick={this.closeMenu}
                />
                <span className="close-text close-offset">Close</span>
              </div>
              <div className="menu-options">
                <button
                  className="cps-btn +primary"
                  onClick={() => this.openModal("automatedTests")}
                >
                  Automated Tests
                </button>
                <button
                  className="cps-btn +secondary"
                  onClick={() => this.openModal("conversions")}
                  style={{
                    marginTop: "4px",
                    border: "1px solid rgb(223, 223, 223)",
                  }}
                >
                  Upload conversion file
                </button>
              </div>
            </div>
          ) : (
            <div className="test-tab" onClick={this.openMenu}>
              <div className="add-test-button">
                <span className="cps-icon cps-icon-add cps-white" />
              </div>
              <span className="test-text">Test</span>
            </div>
          ))}
      </Scoped>
    );
  }
}

const css = k`
  .test-tab {
    position: fixed;
    right: 0;
    top: 240px;
    width: 37px;
    height: 53px;
    background-color: #fff;
    border-radius: 5px 0 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 #b3b3b3;
  }

  .test-menu {
    position: fixed;
    right: 5px;
    top: 240px;
    width: 257px;
    height: 108px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px 0 #b3b3b3;
    padding: 16px 8px 16px 8px;
    z-index: 100;
  }

  .close-menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #e1e1e1;
    padding-right: 8px;
    height: 100%;
  }

  .add-test-button {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: #00bf4b;
  }

  .menu-options {
    height: 100%;
    display: flex;
    padding-left: 8px;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
  }

  .cps-icon-add {
    position: relative;
    top: 1px;
    right: -1px;
    font-size: 17.5px;
  }

  .test-text {
    font-size: 11px;
    font-weight: 500;
    margin: 3px 0 2px 0;
  }

  .close-text {
    font-size: 11px;
    font-weight: 500;
    cursor: default;
  }

  .close-offset {
    position: relative;
    top: -3px;
  }

  .cps-btn {
    width: 175px;
    margin-left: 0;
  }

  .cps-icon-close {
    cursor: pointer;
  }
`;
