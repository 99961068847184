import React from "react";
import { Scoped, k } from "kremling";

export default class Breadcrumbs extends React.Component {
  state = {
    showAllBreadcrumbs: false,
    startIndex: 0,
  };

  componentDidUpdate(prevProps) {
    const length = this.props.breadcrumbs.length;
    const { maxLength } = prevProps;
    if (length !== prevProps.breadcrumbs.length) {
      const startIndex = Math.ceil(length / maxLength - 1) * maxLength;
      this.setState({
        startIndex: startIndex > 0 ? startIndex : 0,
        showAllBreadcrumbs: false,
      });
    } else if (length > prevProps.breadcrumbs.length) {
      this.setState({ showAllBreadcrumbs: false });
    }
  }

  render() {
    const { startIndex, showAllBreadcrumbs } = this.state;
    const currentFolder = this.props.breadcrumbs[0];

    return (
      <Scoped css={css}>
        <div className="breadcrumbs-container">
          <div onClick={this.props.callGetRootDirectory}>
            <span className="cps-icon cps-icon-home" />
          </div>
          {startIndex > 0 && !showAllBreadcrumbs && (
            <div className="elipses-container">
              <span>/</span>
              <span className="elipses" onClick={this.showAllBreadcrumbs}>
                ...
              </span>
              <span>&nbsp;/</span>
            </div>
          )}
          {this.props.breadcrumbs
            .slice()
            .reverse()
            .filter((crumb, i) => {
              return showAllBreadcrumbs || i >= startIndex;
            })
            .map((crumb, i) => {
              return (
                <div className="crumb-section" key={crumb.id}>
                  {crumb.id === currentFolder.id ? (
                    <div className="final-crumb">{crumb.name}</div>
                  ) : (
                    <div
                      className="crumb"
                      onClick={() => this.props.callGetDirectory(crumb.id)}
                    >
                      <span className="crumb-name">{crumb.name}</span>
                      <span className="crumb-separator">/</span>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </Scoped>
    );
  }

  showAllBreadcrumbs = () => {
    this.setState({ showAllBreadcrumbs: true });
  };
}

const css = k`
  .breadcrumbs-container {
    padding: 0.8rem 2.4rem;
    display: flex;
    align-items: center;
    width: 68%;
  }

  .final-crumb {
    color: #3399ff;
    font-weight: bold;
    font-size: 1.3rem;
    cursor: default;
  }

  .crumb {
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .crumb-name:hover {
    text-decoration: underline;
  }

  .elipses-container {
    color: #777777;
    font-size: 1.4rem;
    font-weight: 600;
    margin-right: 8px;
  }

  .elipses {
    cursor: pointer;
  }

  .elipses:hover {
    color: #3399ff;
  }

  .crumb-separator {
    color: #777777;
    font-size: 1.4rem;
    font-weight: 600;
    margin-left: 8px;
    margin-right: 8px;
  }

  .cps-icon-home {
    font-size: 2.4rem;
    margin-right: 16px;
    cursor: pointer;
  }
`;
