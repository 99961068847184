import React from "react";
import { Scoped, a, k } from "kremling";
import { get, isEmpty, find } from "lodash";
import { VersionSelector } from "../ui/version-selector.component";

export default class AutomatedTestsHeader extends React.Component {
  state = {
    versionsList: [],
  };

  componentDidMount() {
    if (!isEmpty(this.props.versions)) {
      this.createVersionList();
    }
  }

  componentDidUpdate(prevProps) {
    if (isEmpty(prevProps.versions) && !isEmpty(this.props.versions)) {
      this.createVersionList();
    }
  }

  createVersionList = () => {
    const { versions } = this.props;
    const versionsList = versions.map(version => {
      return {
        ...version,
        revisions: version.revisions.map(revision => {
          let revisionType;

          switch (revision.status) {
            case "workInProgress":
              revisionType = "Draft - ";
              break;
            case "published":
              revisionType = "Published - ";
              break;
            case "live":
              revisionType = "Prod/Live - ";
              break;
            case "releaseCandidateHotFix":
              revisionType = "RC (hotfix) - ";
              break;
            case "releaseCandidateStandard":
              revisionType = "RC - ";
              break;
            default:
              revisionType = "";
          }

          return {
            ...revision,
            name: `${revisionType}${revision.name}`,
          };
        }),
      };
    });

    this.setState({
      versionsList,
    });
  };

  getCreateRevisionName = () => {
    const { ogRevisionId, ogVersionId } = this.props;

    const ogVersion = find(
      this.state.versionsList,
      version => version.id === ogVersionId
    );

    if (!ogVersion) return;

    return (
      find(ogVersion.revisions, revision => revision.id === ogRevisionId)
        .name || ""
    );
  };

  render() {
    const { activeTab, versionId, revisionId } = this.props;

    return (
      <Scoped css={css}>
        <div className="qa-automation-header">
          <div className="cps-card__header">
            <div className="header-title">Automated Tests</div>
            <div className="header-right-container">
              <div className="sub-header-right">
                {activeTab === "create" && (
                  <div className="create-test-revision-name">
                    {this.getCreateRevisionName()}
                  </div>
                )}
              </div>
              <div className="vertical-line" />
              <a
                onClick={() =>
                  !this.props.isCreating && this.props.onCompleted()
                }
                className="cps-icon cps-icon-close"
              />
            </div>
          </div>
          <div className="sub-header">
            <div className="manage-tabs">
              <div
                className={a("tab-title").m(
                  "active-tab",
                  activeTab === "manage"
                )}
                onClick={() => this.props.setActiveTab("manage")}
              >
                Manage tests
              </div>
              <div
                className={a("tab-title").m(
                  "active-tab",
                  activeTab === "create"
                )}
                onClick={() => this.props.setActiveTab("create")}
              >
                Create tests
              </div>
            </div>
            <div className="sub-header-right">
              {activeTab === "manage" && (
                <VersionSelector
                  versionId={versionId}
                  revisionId={revisionId}
                  versionList={this.state.versionsList}
                  updateVersionAndRevision={this.props.updateVersionAndRevision}
                />
              )}
            </div>
          </div>
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .cps-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 23px 0 16px;
  }

  .manage-tabs {
    display: flex;
    align-items: center;
  }

  .header-title {
    padding: 8px 12px;
    text-align: center;
    font-size: 24px;
  }

  .sub-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1rem solid var(--cps-color-silver);
    padding: 8px;
  }

  .sub-header-right {
    display: flex;
    align-items: center;
  }

  .tab-title {
    padding: 8px 12px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
  }

  .tab-title:hover {
    background-color: var(--cps-color-chrome)
  }

  .active-tab {
    font-weight: 600;
    border-bottom: 2px solid var(--cps-color-primary);
  }

  .header-right-container {
    display: flex;
    align-items: center;
  }

  .vertical-line {
    height: 25px;
    border-left: 1px solid #979797;
    margin-left: 12px;
  }

  .cps-icon-close {
    position: relative;
    right: -8px;
    text-decoration: none;
    color: #777777;
  }

  .create-test-revision-name {
    margin-right: 24px;
    font-size: 16px;
    font-weight: 600;
  }
`;
