import React from "react";
import PropTypes from "prop-types";
import { isEmpty, get, noop } from "lodash";

export default class DragAndDropWrapper extends React.Component {
  state = {
    dragEnter: false,
    isDragging: false,
  };

  handleDragLeave = evt => {
    evt.stopPropagation();
    this.counter--;
    if (this.counter === 0) {
      this.setState({ dragEnter: false });
    }
    this.props.dragLeave && this.props.dragLeave();
  };

  handleDragOver = evt => {
    evt.preventDefault();
  };

  handleDragStart = evt => {
    const dataTransfer = {
      type: "doc",
      payload: this.props.dragItems,
    };
    evt.dataTransfer.setData("text", JSON.stringify(dataTransfer));

    this.dragElement = document.createElement("div");
    this.dragElement.setAttribute(
      "style",
      "opacity: 0; width: 1px; height: 1px;"
    );
    document.body.appendChild(this.dragElement);
    if (evt.dataTransfer.setDragImage)
      evt.dataTransfer.setDragImage(this.dragElement, 0, 0);

    this.props.startDrag && this.props.startDrag();
    this.setState({ isDragging: true });
  };

  handleDragEnd = evt => {
    evt.preventDefault();
    document.body.removeChild(this.dragElement);
    this.counter = 0;
    this.setState({ dragEnter: false, isDragging: false });
    this.props.endDrag && this.props.endDrag();
  };

  handleDrop = evt => {
    evt.preventDefault();
    this.counter = 0;
    this.setState({ dragEnter: false, isDragging: false });
    const dataTransfer = evt.dataTransfer && evt.dataTransfer.getData("text");
    const draggedFiles = dataTransfer ? JSON.parse(dataTransfer).payload : [];
    this.props.onDrop &&
      this.props.onDrop(
        isEmpty(evt.dataTransfer.files) ? draggedFiles : evt.dataTransfer.files,
        this.props.dropItem
      );
  };

  handleDragEnter = evt => {
    evt.stopPropagation();
    this.counter++;
    if (!this.state.dragEnter) {
      this.setState({ dragEnter: true });
    }
    this.props.dragEnter && this.props.dragEnter();
  };

  getDragEnterStyle = () => {
    const defaultStyle = { background: "#E5F4FC", border: "1px solid #3399FF" };
    return this.state.dragEnter
      ? this.props.dragEnterStyle || defaultStyle
      : {};
  };

  getDraggingStyle = () => {
    const defaultStyle = { opacity: 0.6 };
    return this.state.isDragging || this.props.isDragging
      ? this.props.draggedStyled || defaultStyle
      : {};
  };

  counter = 0;

  render() {
    const dragProps = {
      draggable: this.props.isDraggable,
      onDragStart: this.handleDragStart,
      onDragEnd: this.handleDragEnd,
      style: this.getDraggingStyle(),
    };

    const dropProps = {
      onDrop: this.handleDrop,
      onDragLeave: this.handleDragLeave,
      onDragOver: this.handleDragOver,
      onDragEnter: this.handleDragEnter,
      style: this.getDragEnterStyle(),
    };

    const emptyDropProps = {
      onDrop: evt => evt.stopPropagation(),
      onDragLeave: evt => evt.stopPropagation(),
      onDragOver: noop,
      onDragEnter: evt => evt.stopPropagation(),
    };

    const item = this.props.isDropZone ? (
      <div {...dropProps}>{this.props.children}</div>
    ) : this.props.isDraggable ? (
      <div {...dragProps}>{this.props.children}</div>
    ) : (
      <div {...emptyDropProps}>{this.props.children}</div>
    );

    return <div>{item}</div>;
  }
}
