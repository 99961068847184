import React from "react";
import { CprButton } from "canopy-styleguide!sofe";
import { Scoped, always, k } from "kremling";
import Cancelable from "react-disposable-decorator";
import { catchSyncStacktrace } from "auto-trace";
import { successToast, infoToast } from "toast-service!sofe";
import { noop, get, isEmpty, indexOf, pull, flatMap } from "lodash";

import CreateTestName from "./create-test-name.component.js";
import Breadcrumbs from "../../common/breadcrumbs.component.js";
import CreateTestDirectory from "./create-test-directory.component.js";
import Search from "../../ui/search.component.js";
import { Loader } from "../../ui/loader.component";

import { createTest, getTaxForms } from "../../sme-qa-ui.resource.js";
import TaxFormList from "./tax-form-list.component.js";

@Cancelable
export default class CreateTest extends React.Component {
  state = {
    testName: "",
    isCreating: false,
    taxFormFolders: [],
    hasSelectedTests: false,
    selectedTaxForms: [],
    testType: "partial",
  };

  componentDidMount() {
    this.callGetTaxForms();
  }

  callGetTaxForms = () => {
    const {
      versionId,
      revisionId,
      clientId,
      engagementId,
      folderIds,
    } = this.props;
    this.props.cancelWhenUnmounted(
      getTaxForms(
        versionId,
        revisionId,
        clientId,
        engagementId,
        folderIds
      ).subscribe(taxFormFolders => {
        let taxFormsCount = 0;
        taxFormFolders.forEach(folder => {
          if (!folder.extensions) {
            taxFormsCount = taxFormsCount + folder.taxForms.length;
          }
        });
        this.setState({ taxFormFolders, taxFormsCount });
      })
    );
  };

  createTest = () => {
    const {
      clientId,
      engagementId,
      versionId,
      revisionId,
      parentId,
      taxFormType,
      folderIds,
      taxFormName,
    } = this.props;
    const { testName, isCreating, testType } = this.state;
    if (!isCreating) {
      this.setState({ isCreating: true });
      this.props.cancelWhenUnmounted(
        createTest(
          versionId,
          revisionId,
          testName,
          clientId,
          parentId,
          engagementId,
          folderIds,
          testType === "partial" ? this.state.selectedTaxForms : null,
          taxFormName,
          taxFormType
        ).subscribe(test => {
          this.setState({ isCreating: false });
          successToast(`${test.name} was successfully created!`);
          this.props.onCompleted();
        }, this.handleError)
      );
    }
  };

  handleSelectedTest = () => {
    this.setState({ hasSelectedTests: true });
  };

  handleBack = () => {
    this.setState({ hasSelectedTests: false });
  };

  handleError = err => {
    this.setState({ isCreating: false });
    if (get(err, "data.errors.httpStatusCode") === 409) {
      const messageStr = get(err, "data.errors.message");
      const message = messageStr.slice(messageStr.indexOf("|") + 1);
      infoToast(message);
    } else {
      catchSyncStacktrace();
    }
  };

  handleTaxFormRowClick = taxFormId => {
    if (this.isRowSelected(taxFormId)) {
      this.setState(prevState => ({
        selectedTaxForms: pull(prevState.selectedTaxForms, taxFormId),
      }));
    } else {
      this.setState(prevState => ({
        selectedTaxForms: [...prevState.selectedTaxForms, taxFormId],
      }));
    }
  };

  isRowSelected = taxFormName => {
    return indexOf(this.state.selectedTaxForms, taxFormName) !== -1;
  };

  areAllRowsSelected = () => {
    return this.state.taxFormsCount === this.state.selectedTaxForms.length;
  };

  handleTestTypeClick = event => {
    if (!isEmpty(get(event, "target.value"))) {
      this.setState({ testType: event.target.value });
    }
  };

  handleSelectAllRows = (forceSelectAll = false) => {
    let selectedTaxForms = [];

    if (forceSelectAll || !this.areAllRowsSelected()) {
      selectedTaxForms = [
        ...flatMap(this.state.taxFormFolders, folder =>
          flatMap(folder.taxForms, form => form.name)
        ),
      ];
    }

    this.setState(prevState => ({
      selectedTaxForms,
    }));
  };

  render() {
    return (
      <Scoped css={css}>
        {!this.state.hasSelectedTests ? (
          <div className="tax-form-list-wrapper">
            {!isEmpty(this.state.taxFormFolders) ? (
              <TaxFormList
                taxFormFolders={this.state.taxFormFolders}
                onTaxFormRowClick={this.handleTaxFormRowClick}
                isRowSelected={this.isRowSelected}
                areAllRowsSelected={this.areAllRowsSelected}
                onSelectAllRows={this.handleSelectAllRows}
                totalTaxFormsCount={this.state.taxFormsCount}
                selectedTaxFormsCount={this.state.selectedTaxForms.length}
                onTestTypeClick={this.handleTestTypeClick}
                testType={this.state.testType}
              />
            ) : (
              <Loader color="rainbow" size="large" overlay />
            )}
          </div>
        ) : (
          <div>
            <CreateTestName
              testName={this.state.testName}
              editTestName={this.editTestName}
            />
            <div className="sub-section">
              <Breadcrumbs
                breadcrumbs={this.props.breadcrumbs}
                callGetDirectory={this.props.callGetDirectory}
                callGetRootDirectory={this.props.callGetRootDirectory}
                maxLength={5}
              />
              <Search
                search={this.props.search}
                editSearch={this.props.editSearch}
                clearSearch={this.props.clearSearch}
              />
              <a
                onClick={this.props.createFolder}
                className="cps-icon cps-icon-new-folder"
                style={{ fontSize: "26px", width: "4%" }}
              />
            </div>
            <CreateTestDirectory
              parentId={this.props.parentId}
              showDirectory={this.props.showDirectory}
              callGetDirectory={this.props.callGetDirectory}
              directory={this.props.directory.map(i =>
                i.isTest ? { ...i, disabled: true } : i
              )}
              newFolder={this.props.newFolder}
              addFolder={this.props.addItem}
              cancelFolder={this.props.cancelFolder}
              versionId={this.props.versionId}
              revisionId={this.props.revisionId}
              handleTestClick={noop}
              searchResults={
                this.props.searchResults
                  ? this.props.searchResults.filter(i => !i.isTest)
                  : this.props.searchResults
              }
            />
          </div>
        )}
        <div
          className={always("cps-modal__dialog__actions").maybe(
            "buttons",
            this.state.isCreating
          )}
        >
          {this.state.hasSelectedTests ? (
            <CprButton
              actionType="primary"
              disabled={!(this.state.testName.trim() && this.props.parentId)}
              onClick={this.createTest}
            >
              {this.state.isCreating ? (
                <div className="loading">
                  <Loader color="rainbow" />
                </div>
              ) : (
                "Save"
              )}
            </CprButton>
          ) : (
            <CprButton
              actionType="primary"
              onClick={this.handleSelectedTest}
              disabled={isEmpty(this.state.selectedTaxForms)}
            >
              Create
            </CprButton>
          )}
          {this.state.hasSelectedTests && (
            <CprButton actionType="flat" onClick={this.handleBack}>
              Back
            </CprButton>
          )}
          <CprButton
            actionType="flat"
            onClick={this.props.onCompleted}
            disabled={this.state.isCreating}
          >
            Cancel
          </CprButton>
        </div>
      </Scoped>
    );
  }

  editTestName = evt => {
    this.setState({ testName: evt.target.value });
  };
}

const css = k`
  .cps-modal__dialog__actions > button {
    width: 90px;
  }

  .cps-modal__dialog__actions {
    border-top: 0.1rem solid #e9e9e9;
  }

  .buttons > button {
    padding: 0 12px !important;
  }

  .loading > cps-loader > div {
    padding: 0 !important;
  }

  .tax-form-list-wrapper {
    height: 612px;
    position: relative;
  }

  .sub-section {
    display: flex;
    border-top: 0.1rem solid var(--cps-color-silver);
    align-items: center;
    border-bottom: 0.1rem solid #e9e9e9;
  }
`;
