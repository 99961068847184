import React from "react";
import Cancelable from "react-disposable-decorator";
import { fromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import { Scoped, a, k } from "kremling";
import { CprIcon } from "canopy-styleguide!sofe";

@Cancelable
export default class ManageTestActions extends React.Component {
  state = {
    kabobOpen: false,
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      fromEvent(document, "click")
        .pipe(filter(event => !this.kabobRef.contains(event.target)))
        .subscribe(() => {
          this.setState({ kabobOpen: false });
        })
    );
  }

  render() {
    const {
      certifiable,
      isRootFolder,
      handleCertification,
      handleEditClick,
      handleDeleteClick,
      handleMoveClick,
      handleOverwriteTest,
      handlePullTest,
      handleRunNightly,
      item,
    } = this.props;
    const { kabobOpen } = this.state;

    return (
      <Scoped css={css}>
        <div
          className="cps-icon-actions"
          ref={ref => {
            this.kabobRef = ref;
          }}
        >
          {item.certified && (
            <CprIcon name="checkmark-line-badge" className="icon-action" />
          )}
          {item.runNightly && (
            <CprIcon name="af-refresh-nightly" className="icon-action" />
          )}
          <div className={a("dropdown-kabob").m("open-kabob", kabobOpen)}>
            <CprIcon
              name="misc-kabob"
              onClick={() => {
                this.setState(prevState => ({
                  kabobOpen: !prevState.kabobOpen,
                }));
              }}
            />
          </div>
          {kabobOpen && (
            <div className="action-items-dropdown-wrapper">
              <div className="action-items-dropdown">
                {isRootFolder && !item.isTest && (
                  <div
                    className={a("icon-action-item").m(
                      "active-badge",
                      item.runNightly
                    )}
                    onClick={() => {
                      this.setState({ kabobOpen: false }),
                        handleRunNightly(item);
                    }}
                    style={{
                      borderBottom: "1px solid var(--cps-color-athens)",
                    }}
                  >
                    <CprIcon
                      name="af-refresh-nightly"
                      className="icon-action"
                    />
                    Run nightly
                    {item.runNightly && (
                      <CprIcon
                        name="checkmark-small"
                        className="icon-action"
                        style={{ marginLeft: "8px" }}
                      />
                    )}
                  </div>
                )}
                {((certifiable && item.isTest) || item.certified) && (
                  <div
                    className={a("icon-action-item").m(
                      "active-badge",
                      item.certified
                    )}
                    onClick={() => {
                      this.setState({ kabobOpen: false }),
                        handleCertification(item);
                    }}
                    style={{
                      borderBottom: "1px solid var(--cps-color-athens)",
                    }}
                  >
                    <CprIcon
                      name="checkmark-line-badge"
                      className="icon-action"
                    />
                    {item.certified ? "Certified" : "Certify"}
                    {item.certified && (
                      <CprIcon
                        name="checkmark-small"
                        className="icon-action"
                        style={{ marginLeft: "8px" }}
                      />
                    )}
                  </div>
                )}
                <div
                  className="icon-action-item"
                  onClick={() => {
                    this.setState({ kabobOpen: false }), handleEditClick(item);
                  }}
                >
                  <CprIcon name="crud-pencil" className="icon-action" />
                  Edit
                </div>
                <div
                  className="icon-action-item"
                  onClick={() => {
                    this.setState({ kabobOpen: false }),
                      handleDeleteClick(item);
                  }}
                >
                  <CprIcon name="crud-trash-small" className="icon-action" />
                  Delete
                </div>
                <div
                  className="icon-action-item"
                  onClick={() => {
                    this.setState({ kabobOpen: false }), handleMoveClick(item);
                  }}
                >
                  <CprIcon name="folder-right" className="icon-action" />
                  Move
                </div>
                {item.isTest && (
                  <div
                    className="icon-action-item"
                    onClick={() => {
                      this.setState({ kabobOpen: false }), handlePullTest(item);
                    }}
                  >
                    <CprIcon
                      name="communication-letter-back"
                      className="icon-action"
                    />
                    Pull
                  </div>
                )}
                {item.isTest && (
                  <div
                    className="icon-action-item"
                    onClick={() => {
                      this.setState({ kabobOpen: false }),
                        handleOverwriteTest(item);
                    }}
                  >
                    <CprIcon
                      name="communication-email-template"
                      className="icon-action"
                    />
                    Overwrite
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .action-items-dropdown-wrapper {
    position: relative;
  }

  .action-items-dropdown {
    cursor: pointer;
    position: absolute;
    margin-top: 16px;
    margin-left: -154px;
    background-color:  var(--cps-color-bumble);
    width: 16rem;
    border: 1px solid var(--cps-color-athens);
    border-radius: 4px;
    z-index: 100000;
  }

  .cps-icon-actions {
    display: flex;
    align-items: center;
    right: 0;
    padding-right: 2.4rem;
  }

  .dropdown-kabob {
    transform: rotate(-90deg);
    transition: all 400ms;
    cursor: pointer;
  }

  .open-kabob {
    transform: none;
    transition: all 400ms;
  }

  .icon-action-item {
    padding: 8px 8px 4px;
    cursor: pointer;
  }

  .icon-action-item:hover {
    background-color: var(--cps-color-blue-smoke);
  }

  .icon-action {
    padding-right: 8px;
    margin-bottom: 4px;
  }

  .active-badge {
    color: var(--cps-color-primary);
  }
`;
