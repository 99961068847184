import React from "react";
import { Scoped, always, k } from "kremling";
import { createFolder } from "../../sme-qa-ui.resource.js";
import { catchSyncStacktrace } from "auto-trace";
import Cancelable from "react-disposable-decorator";
import { get, isEmpty } from "lodash";
import { infoToast } from "toast-service!sofe";
import { Loader } from "../../ui/loader.component";

@Cancelable
export default class CreateTestDirectory extends React.Component {
  state = {
    newFolderName: "",
    displayedFoldersAndTests: [],
    isFilterOpen: false,
    filter: { name: "", date: "", status: "" },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !nextProps.searchResults &&
      prevState.displayedFoldersAndTests !== nextProps.directory
    ) {
      return { displayedFoldersAndTests: nextProps.directory };
    } else if (
      nextProps.searchResults &&
      prevState.displayedFoldersAndTests !== nextProps.searchResults
    ) {
      return { displayedFoldersAndTests: nextProps.searchResults };
    } else {
      return null;
    }
  }

  cancelClick = () => {
    this.setState({ newFolderName: "" }, this.props.cancelFolder);
  };

  handleChange = evt => {
    this.setState({ newFolderName: evt.target.value });
  };

  handleAddSubmit = evt => {
    const { versionId, revisionId, parentId } = this.props;
    const { newFolderName } = this.state;
    if (newFolderName.trim() && get(evt, "relatedTarget.tabIndex") !== 1) {
      this.props.cancelWhenUnmounted(
        createFolder(
          versionId,
          revisionId,
          newFolderName.trim(),
          parentId
        ).subscribe(
          folder => {
            this.setState({ newFolderName: "" }, () =>
              this.props.addFolder(folder)
            );
          },
          err => {
            this.setState({ newFolderName: "" });
            if (get(err, "data.errors.httpStatusCode") === 409) {
              const messageStr = get(err, "data.errors.message");
              const message = messageStr.slice(messageStr.indexOf("|") + 1);
              infoToast(message);
            } else {
              catchSyncStacktrace();
            }
          }
        )
      );
    }
  };

  componentDidUpdate() {
    if (this.props.newFolder) {
      this.el && this.el.focus();
    }
  }

  render() {
    return (
      <Scoped css={css}>
        <div className="cps-flex-table">
          <div className="cps-flex-table__header">
            <div className="cps-flex-table__col">
              <span className="folder-col">Name</span>
            </div>
            <div className="cps-flex-table__col">Date created</div>
          </div>
          <div className="folder-container">
            {this.props.showDirectory && this.props.newFolder && (
              <div className="cps-flex-table__row">
                <div className="cps-flex-table__col new-folder-container">
                  <i className="cps-icon cps-icon-folder new-folder-icon" />
                  <span className="cps-icon cps-icon-add cps-white new-folder-add" />
                  <input
                    className="cps-form-control new-folder-input"
                    value={this.state.newFolderName}
                    onChange={this.handleChange}
                    onBlur={this.handleAddSubmit}
                    onKeyPress={e =>
                      e.key === "Enter" && this.handleAddSubmit()
                    }
                    ref={el => (this.el = el)}
                  />
                  <span
                    className="cps-link"
                    onClick={this.cancelClick}
                    tabIndex="1"
                  >
                    Cancel
                  </span>
                </div>
              </div>
            )}
            {this.props.showDirectory ? (
              !isEmpty(this.state.displayedFoldersAndTests) ? (
                this.state.displayedFoldersAndTests.map(item => {
                  return (
                    <div
                      key={item.id}
                      className={always("cps-flex-table__row")
                        .maybe(
                          "selected-row",
                          item.id === get(this.props, "selectedTest.id")
                        )
                        .maybe("disabled-row", item.disabled)}
                    >
                      <div
                        className="cps-flex-table__col folder-name-container"
                        onClick={() => this.itemClick(item)}
                      >
                        <i
                          className={always("cps-icon").toggle(
                            "cps-icon-forms",
                            "cps-icon-folder",
                            item.isTest
                          )}
                        />
                        <span className="folder-name">{item.name}</span>
                      </div>
                      <div
                        className="cps-flex-table__col"
                        onClick={() => this.itemClick(item)}
                      >
                        {item.date}
                      </div>
                    </div>
                  );
                })
              ) : (
                this.showEmptyState()
              )
            ) : (
              <Loader color="rainbow" size="large" overlay />
            )}
          </div>
        </div>
      </Scoped>
    );
  }

  itemClick = item => {
    if (item.isTest) {
      this.props.handleTestClick(item);
    } else {
      this.props.callGetDirectory(item.id, this.props.versionId);
    }
  };

  showEmptyState = () => {
    if (this.props.searchResults && this.props.searchResults.length === 0) {
      return (
        <div className="empty-state">
          <img src="https://s3-us-west-2.amazonaws.com/cdn.canopytax.com/images/empty-search.png" />
        </div>
      );
    } else if (!this.props.newFolder) {
      return (
        <div className="empty-state">
          <img src="https://s3-us-west-2.amazonaws.com/cdn.canopytax.com/images/empty-folder.png" />
        </div>
      );
    }
    return null;
  };
}

const css = k`
  .cps-flex-table__col {
    display: flex;
    align-items: center;
  }

  .cps-flex-table__row {
    cursor: pointer;
  }

  .cps-flex-table__row:hover {
    background-color: #f2f2f2;
  }

  .disabled-row {
    cursor: default;
  }

  .disabled-row:hover {
    background-color: #fff;
  }

  .disabled-row > div {
    color: #C9C9C9 !important;
  }

  .disabled-row > div > i {
    color: #C9C9C9 !important;
  }

  .selected-row {
    background-color: #f2f2f2;
  }

  .cps-icon-folder {
    margin-right: 16px;
  }

  .cps-icon-forms {
    margin-right: 16px;
  }

  .folder-name {
    font-size: 14px;
    overflow-wrap: break-word;
    word-break: break-word;
    padding-right: 8px;
    width: 65rem;
  }

  .folder-col {
    padding-left: 40px;
    width: 65rem;
  }

  .folder-container {
    height: 460px;
    overflow: auto;
    position: relative;
  }

  .folder-name-container {
    min-width: 70rem;
  }

  .folder-name-header {
    padding-left: 40px;
  }

  .cps-modal__dialog__actions {
    border-top: 0.1rem solid #e9e9e9;
  }

  .new-folder-container {
    background-color: #f2f2f2;
  }

  .new-folder-icon {
    color: #00bf4b;
  }

  .new-folder-input {
    border-color: #f2f2f2;
    background-color: #f2f2f2;
    position: relative;
    right: 28px;
    padding-left: 3px;
  }

  .new-folder-input:focus {
    box-shadow: none !important;
  }

  .new-folder-add {
    right: 34px;
    top: 2px;
  }

  .empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 18px;
  }
`;
