import React from "react";
import PropTypes from "prop-types";
import { Scoped, always, k } from "kremling";
import { fromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import {
  CprTab,
  CprButtonIcon,
  CprButton,
  CprIcon,
} from "canopy-styleguide!sofe";
import Cancelable from "react-disposable-decorator";
import { uniq, isEmpty, reduce } from "lodash";
import ManageTestsErrorsFormTable from "./manage-tests-errors-form-table.component";
import { resetTest } from "src/sme-qa-ui.resource.js";
import { successToast, warningToast } from "toast-service!sofe";
import { Loader } from "../../ui/loader.component";

@Cancelable
export default class ManageTestsErrors extends React.Component {
  static propTypes = {
    versionId: PropTypes.string,
    revisionId: PropTypes.string,
    selectedTest: PropTypes.object,
    hideCalculations: PropTypes.func,
  };

  state = {
    activeTabIndex: 0,
    resettingTest: false,
    kabobOpen: false,
    expandAll: false,
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      fromEvent(document, "click")
        .pipe(filter(event => !this.kabobRef.contains(event.target)))
        .subscribe(() => {
          this.setState({ kabobOpen: false });
        })
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedTest !== prevProps.selectedTest) {
      this.setState({ activeTabIndex: 0 });
    }
  }

  render() {
    const { status } = this.props.selectedTest;
    const { selectedTest } = this.props;

    return (
      <Scoped css={css}>
        <div className="calculations-container">
          <div className="calculations-header">
            <strong className="test-name">
              <CprIcon
                name="caret-large-left"
                style={{ paddingRight: "10px", cursor: "pointer" }}
                onClick={this.props.hideCalculations}
              />
              {this.props.selectedTest.name}
            </strong>
            <div className="calculations-header-right-hand">
              {selectedTest.failedResults && (
                <div className="failed-count">
                  {`Failed: ${
                    selectedTest.errorCount
                  } forms, ${this.getFailedTestCount()} errors`}
                </div>
              )}
              <div
                className={always("cps-dropdown").maybe(
                  "cps-open",
                  this.state.kabobOpen
                )}
                ref={ref => {
                  this.kabobRef = ref;
                }}
              >
                <CprButtonIcon
                  icon="misc-kabob"
                  onClick={() => {
                    this.setState(prevState => ({
                      kabobOpen: !prevState.kabobOpen,
                    }));
                  }}
                  customClass="kabob-menu"
                />
                <ul className="cps-dropdown-menu cps-pull-right">
                  <li>
                    <CprButton onClick={this.handleResetClicked}>
                      Reset test
                    </CprButton>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="calculations-sub-header">
            <div className="calculations-menu-options">
              <CprTab
                name={status === "error" ? "Syntax" : "Calculations"}
                tabIndex={0}
                activeTabIndex={this.state.activeTabIndex}
                events={{ tabclicked: this.handleTabClicked }}
                customClassName={"tab-style"}
              />
              <CprTab
                name={"Details"}
                tabIndex={1}
                activeTabIndex={this.state.activeTabIndex}
                events={{ tabclicked: this.handleTabClicked }}
                customClassName={"tab-style"}
              />
            </div>
            {selectedTest.status === "failed" && (
              <label className="cps-toggle">
                Expand all
                <input
                  type="checkbox"
                  checked={this.state.expandAll}
                  onChange={e => this.setState({ expandAll: e.target.checked })}
                />
                <span style={{ margin: "0 8px" }} />
              </label>
            )}
          </div>
          <div className="calculations-body">
            {this.state.activeTabIndex === 0 ? (
              <div className="calculations-body-scroll">
                {selectedTest.isLoaded && !this.state.resettingTest ? (
                  !isEmpty(selectedTest.failedResults) ? (
                    <div>
                      {selectedTest.status === "failed" && (
                        <div className="errors-header">
                          <div>Form</div>
                          <div>Failed</div>
                        </div>
                      )}
                      {selectedTest.failedResults.map((result, i, arr) => {
                        return selectedTest.status === "failed" ? (
                          <div
                            className="manage-tests-errors-form-table"
                            key={Object.keys(result)[0]}
                          >
                            <ManageTestsErrorsFormTable
                              form={result}
                              expandAll={this.state.expandAll}
                            />
                          </div>
                        ) : (
                          result.errors[0].details.map((detail, i) => {
                            return (
                              <div className="errors-row" key={detail}>
                                <span>{detail}</span>
                              </div>
                            );
                          })
                        );
                      })}
                    </div>
                  ) : selectedTest.status === "passed" ? (
                    <div className="form-field-div">
                      <strong>Passed</strong>
                      <span>Expected calculations passed</span>
                    </div>
                  ) : (
                    <div className="form-field-div">
                      <strong>Start test</strong>
                      <span>Click run to execute test</span>
                    </div>
                  )
                ) : (
                  <Loader color="rainbow" size="large" />
                )}
              </div>
            ) : isEmpty(selectedTest.taxFormNames) ? (
              <div className="calculations-body-scroll">
                <div style={{ paddingLeft: "8px" }}>
                  <b>Full test:</b>
                  <div style={{ display: "flex", margin: "10px 0" }}>
                    <i className="cps-icon cps-icon-pdf-file" />
                    <div style={{ marginLeft: "10px" }}>All forms</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="calculations-body-scroll">
                <div style={{ paddingLeft: "8px" }}>
                  <b>Forms captured in test:</b>
                  {uniq(selectedTest.taxFormNames).map(taxFormName => (
                    <div
                      style={{ display: "flex", margin: "10px 0" }}
                      key={taxFormName}
                    >
                      <i className="cps-icon cps-icon-files" />
                      <div style={{ marginLeft: "10px" }}>{taxFormName}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Scoped>
    );
  }

  getFailedTestCount = () => {
    const { failedResults } = this.props.selectedTest;

    return reduce(
      failedResults,
      (acc, result) => {
        return result[Object.keys(result)[0]].length + acc;
      },
      0
    );
  };

  handleTabClicked = customEvent => {
    this.setState({ activeTabIndex: customEvent.detail });
  };

  handleResetClicked = event => {
    event.target.a;
    this.setState({ resettingTest: true, kabobOpen: false });
    const { versionId, revisionId, selectedTest } = this.props;
    this.props.cancelWhenUnmounted(
      resetTest(versionId, revisionId, selectedTest).subscribe(
        () => {
          this.setState({ resettingTest: false });
          this.props.hideCalculations();
          successToast(
            `${
              selectedTest.name
            } was successfully reset! Click "Run" to re-run the test.`
          );
        },
        error => warningToast("Error resetting test")
      )
    );
  };
}

const css = k`
  .calculations-container {
    flex-grow: 1;
    border-left: 0.1rem solid #e9e9e9;
    min-width: 350px;
    display: flex;
    flex-direction: column;
  }

  .calculations-header {
    background-color: var(--cps-color-ash);
    color: var(--cps-color-monsoon);
    font-weight: bold;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
  }

  .calculations-sub-header {
    background-color: var(--cps-color-bumble);
    padding: 0 16px 4px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }

  .calculations-menu-options {
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .calculations-header-right-hand {
    display: flex;
    justify-content: flex-end;
  }

  .calculations-body {
    padding: 0 16px;
    position: relative;
    height: 100%;
    background-color: var(--cps-color-ash);
  }

  .calculations-body-scroll {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-top: 12px;
  }

  .failed-count {
    color: var(--cps-color-mandy);
    font-size: 18px;
    font-weight: bold;
    margin-right: 8px;
    width: 22rem;
  }

  .errors-row {
    margin: 8px;
  }

  .form-field-div {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding-left: 8px;
  }

  .test-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 16px;
  }

  .tab-style {
    margin-left: 0;
    outline: none;
    font-size: 14px;
  }

  .kabob-menu {
    color: var(--cps-color-monsoon);
  }

  .errors-header {
    display: flex;
    font-size: 16px;
    color: var(--cps-color-monsoon);
    font-weight: 600;
    justify-content: space-between;
    padding: 8px 64px 8px 24px;
    border-bottom: 1px solid var(--cps-color-af);
    background-color: var(--cps-color-ash);
  }
`;
