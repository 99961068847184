import React from "react";
import { Scoped, k, toggle } from "kremling";
import DragAndDropWrapper from "../../common/drag-and-drop-wrapper.component.js";
import ManageTestsMoveItem from "./manage-tests-move-item.component.js";
import { isEmpty } from "lodash";

export default class ManageTestsMove extends React.Component {
  render() {
    return (
      <Scoped css={css}>
        <div className="move-container">
          <div className="move-header">
            <div className="move-header-left">
              <img
                src="https://s3-us-west-2.amazonaws.com/cdn.canopytax.com/images/files-inbox-blue.svg"
                className="move-inbox"
              />
              <span>Move inbox</span>
            </div>
            <i
              className="cps-icon cps-icon-sidebar"
              onClick={this.props.closeMoveInbox}
            />
          </div>
          <div className="move-sub-header">
            <strong>Select and drag to folder</strong>
            <img
              className="select-drag-img"
              src="https://cdn.canopytax.com/images/select-drag-tutorial.svg"
            />
          </div>
          {!isEmpty(this.props.moveList) && (
            <div className="select-all-container">
              <label className="cps-checkbox">
                <input
                  type="checkbox"
                  checked={this.props.areAllItemsSelected}
                  onChange={this.props.handleSelectAllClick}
                />
                <span className="select-all">Select all</span>
              </label>
            </div>
          )}
          <div className="move-body">
            <div
              className={toggle(
                "empty-move-list",
                "cps-hidden",
                isEmpty(this.props.moveList)
              )}
            >
              <img src="https://s3-us-west-2.amazonaws.com/cdn.canopytax.com/images/empty-file-inbox.png" />
            </div>
            <div className="move-body-scroll">
              {this.props.moveList.map(item => {
                return (
                  <DragAndDropWrapper
                    key={item.id}
                    isDraggable={true}
                    startDrag={() => this.props.dragStart(item)}
                    endDrag={this.props.dragEnd}
                    isDragging={
                      this.props.draggedItems &&
                      !!this.props.draggedItems.filter(i => i.id === item.id)
                        .length
                    }
                  >
                    <ManageTestsMoveItem
                      item={item}
                      removeFromMoveList={this.props.removeFromMoveList}
                      selectMoveItem={this.props.selectMoveItem}
                    />
                  </DragAndDropWrapper>
                );
              })}
            </div>
          </div>
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .move-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 350px;
  }

  .move-header {
    background-color: #ebebeb;
    color: #4a4a4a;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    height: 56px;
  }

  .move-header-left {
    display: flex;
    align-items: center;
  }

  .move-inbox {
    margin-right: 16px;
  }

  .select-all-container {
    background-color: #2B343A;
    padding: 8px 16px;
    color: #FFFFFF;
  }

  .move-sub-header {
    background-color: #3399FF;;
    color: #fff;
    height: 64px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cps-icon-sidebar {
    cursor: pointer;
    color: #00bf4b;
  }

  .move-body {
    background-color: #3B444A;
    position: relative;
    height: 100%;
  }

  .move-body-scroll {
    background-color: #3B444A;
    position: absolute;
    padding: 16px 40px 16px 12px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .empty-move-list {
    position: absolute;
    left: 3px;
    padding-top: 72px;
    z-index: 10;
  }

  .select-drag-img {
    height: 38px;
  }
`;
