import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import Root from "./root.component.js";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  childAppName: "sme-qa-ui",
  featureToggles: [],
  hotload: {
    dev: {
      enabled: true,
      waitForUnmount: false,
    },
  },
});

export const bootstrap = [
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
];

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

function domElementGetter() {
  let el = document.getElementById("sme-qa-ui");
  if (!el) {
    el = document.createElement("div");
    el.id = "sme-qa-ui";
    document.body.appendChild(el);
  }
  return el;
}

export { floatingTestMenu } from "./test-menu/test-menu.parcel.js";
