import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";
import { isEmpty } from "lodash";

export function getRootDirectory(version, revision) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/test-folders`
  ).pipe(pluck("test-folders"));
}

export function getDirectory(version, revision, folderId) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/test-folders/${folderId}`
  ).pipe(pluck("test-folders"));
}

export function getSearchResults(version, revision, name, folderId, failed) {
  const encodedName = encodeURIComponent(name);

  if (folderId && failed) {
    return fetchAsObservable(
      `/versions/${version}-${revision}/test-folders/${folderId}?name=${encodedName}&failed=true`
    ).pipe(pluck("test-folders"));
  } else if (folderId) {
    return fetchAsObservable(
      `/versions/${version}-${revision}/test-folders/${folderId}?name=${encodedName}`
    ).pipe(pluck("test-folders"));
  } else if (failed) {
    return fetchAsObservable(
      `/versions/${version}-${revision}/test-folders?name=${encodedName}&failed=true`
    ).pipe(pluck("test-folders"));
  } else {
    return fetchAsObservable(
      `/versions/${version}-${revision}/test-folders?name=${encodedName}`
    ).pipe(pluck("test-folders"));
  }
}

export function getTest(version, revision, testId) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/auto-tests/${testId}/test-runs?limit=1&form-grouping=true`
  ).pipe(pluck("test-runs"));
}

export function getAllFailedTests(version, revision, folderId) {
  return folderId
    ? fetchAsObservable(
        `/versions/${version}-${revision}/test-folders/${folderId}?failed=true`
      ).pipe(pluck("test-folders"))
    : fetchAsObservable(
        `/versions/${version}-${revision}/test-folders?failed=true`
      ).pipe(pluck("test-folders"));
}

export function createTest(
  version,
  revision,
  name,
  clientId,
  folderParentId,
  engagementId,
  folderIds,
  taxFormNames,
  taxFormName,
  taxFormType
) {
  let body = { name, description: "", clientId, folderParentId, engagementId };
  if (taxFormType === "taxRes") {
    body = { ...body, taxFormNames: [taxFormName] };
  } else if (taxFormType === "taxPrep") {
    body = isEmpty(taxFormNames)
      ? { ...body, folderIds }
      : { ...body, taxFormNames };
  }

  return fetchAsObservable(`/versions/${version}-${revision}/auto-tests`, {
    method: "POST",
    body: JSON.stringify({
      "auto-tests": body,
    }),
  }).pipe(pluck("auto-tests"));
}

export function createFolder(version, revision, name, parentId) {
  return fetchAsObservable(`/versions/${version}-${revision}/test-folders`, {
    method: "POST",
    body: JSON.stringify({
      "test-folders": {
        name: name,
        parentId: parentId,
      },
    }),
  }).pipe(pluck("test-folders"));
}

export function editTest(
  version,
  revision,
  test,
  parentId,
  engagementId,
  clientId
) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/auto-tests/${test.id}`,
    {
      method: "PATCH",
      body: JSON.stringify({
        "auto-tests": {
          name: test.name,
          description: test.description,
          enabled: test.enabled,
          folderParentId: parentId || test.folderParentId,
          engagementId: engagementId,
          clientId: clientId,
          certified: test.certified || false,
        },
      }),
    }
  ).pipe(pluck("auto-tests"));
}

export function editFolder(version, revision, folder, parentId) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/test-folders/${folder.id}`,
    {
      method: "PATCH",
      body: JSON.stringify({
        "test-folders": {
          name: folder.name,
          description: folder.description,
          enabled: folder.enabled,
          parentId: parentId ? parentId : null,
          runNightly: folder.runNightly || false,
        },
      }),
    }
  ).pipe(pluck("test-folders"));
}

export function restoreTest(version, revision, test) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/auto-tests/${test.id}`,
    {
      method: "PATCH",
      body: JSON.stringify({
        "auto-tests": {
          restore: true,
        },
      }),
    }
  ).pipe(pluck("auto-tests"));
}

export function restoreFolder(version, revision, folder) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/test-folders/${folder.id}`,
    {
      method: "PATCH",
      body: JSON.stringify({
        "test-folders": {
          restore: true,
        },
      }),
    }
  ).pipe(pluck("test-folders"));
}

export function resetTest(version, revision, test) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/auto-tests/${test.id}`,
    {
      method: "PATCH",
      body: JSON.stringify({
        "auto-tests": { reset: true },
      }),
    }
  ).pipe(pluck("auto-tests"));
}

export function deleteTest(version, revision, test) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/auto-tests/${test.id}`,
    {
      method: "DELETE",
    }
  ).pipe(pluck("auto-tests"));
}

export function deleteFolder(version, revision, folder) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/test-folders/${folder.id}`,
    {
      method: "DELETE",
    }
  ).pipe(pluck("test-folders"));
}

export function runTest(version, revision, testId) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/auto-tests/${testId}/test-runs`,
    {
      method: "POST",
    }
  ).pipe(pluck("test-runs"));
}

export function runFolderTests(version, revision, testId) {
  return fetchAsObservable(
    `/versions/${version}-${revision}/test-folders/${testId}/test-runs`,
    {
      method: "POST",
    }
  ).pipe(pluck("test-folders"));
}

export function updateEngagement(version, revision, engagement, client, test) {
  return fetchAsObservable(
    `/clients/${client}/engagements/${engagement}/versions/${version}-${revision}/auto-tests/${test}/answers`,
    {
      method: "PUT",
    }
  ).pipe(pluck("answers"));
}

export function getTaxForms(version, revision, client, engagement, folderIds) {
  let expandedFolderIds = [];
  if (!isEmpty(folderIds)) {
    expandedFolderIds = folderIds.map(folderId => `&folderId=${folderId}`);
  }

  return fetchAsObservable(
    `/clients/${client}/engagements/${engagement}/versions/${version}-${revision}/tax-forms?eSignatures=false&includeEfile=true&excludeHiddenTaxForms=true${expandedFolderIds.join(
      ""
    )}`
  ).pipe(pluck("tax-forms", "taxFormFolders"));
}

export function getVendorsList(versionId, revisionId) {
  return fetchAsObservable(`/versions/${versionId}-${revisionId}/vendors`).pipe(
    pluck("vendors")
  );
}

export function getExtensionsList() {
  return fetchAsObservable("/vendor-file-extensions?year=2018").pipe(
    pluck("vendor-file-extensions")
  );
}

export function getRevisionsList(version) {
  return fetchAsObservable(`/versions/${version}`).pipe(pluck("versions"));
}

export function uploadConversionFile(
  versionId,
  revisionId,
  clientId,
  engagementId,
  vendorId,
  file
) {
  let formData = new FormData();
  formData.append("file", file);

  return fetchAsObservable(
    `/clients/${clientId}/engagements/${engagementId}/versions/${versionId}-${revisionId}/vendors/${vendorId}/answers`,
    {
      method: "PUT",
      body: formData,
    }
  ).pipe(pluck("answers"));
}

export function getVersions() {
  return fetchAsObservable("/versions").pipe(pluck("versions"));
}
