import React from "react";
import PropTypes from "prop-types";
import { get, map } from "lodash";

export default class TaxFormList extends React.Component {
  static propTypes = {
    taxFormFolders: PropTypes.array,
    onTaxFormRowClick: PropTypes.func,
    isRowSelected: PropTypes.func,
    areAllRowsSelected: PropTypes.func,
    onSelectAllRows: PropTypes.func,
    totalTaxFormsCount: PropTypes.number,
    selectedTaxFormsCount: PropTypes.number,
    onTestTypeClick: PropTypes.func,
    testType: PropTypes.string,
  };

  componentDidMount() {
    this.props.onSelectAllRows(true);
  }

  render() {
    return (
      <div style={{ padding: "8px 20px", position: "relative" }}>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            paddingBottom: "10px",
          }}
        >
          Forms to capture
        </div>
        <div
          className="cps-well"
          style={{ overflowY: "scroll", height: "560px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "18px 0 6px 20px",
            }}
          >
            <label className="cps-radio" style={{ marginBottom: "12px" }}>
              <input
                type="radio"
                value="full"
                onClick={this.props.onTestTypeClick}
                checked={this.props.testType === "full"}
              />
              <span>Full test</span>
            </label>
            <label className="cps-radio">
              <input
                type="radio"
                value="partial"
                onClick={this.props.onTestTypeClick}
                checked={this.props.testType === "partial"}
              />
              <span>Partial test</span>
            </label>
          </div>
          {this.props.testType === "partial" && (
            <div>
              <div style={{ margin: "18px 15px 6px 40px" }}>
                <label className="cps-checkbox">
                  <input
                    type="checkbox"
                    checked={this.props.areAllRowsSelected()}
                    onClick={() => {
                      this.props.onSelectAllRows();
                    }}
                  />
                  <span>All forms</span>
                </label>
              </div>
              {map(this.props.taxFormFolders, taxFormFolder => {
                if (!taxFormFolder.taxForms) return;

                return (
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    key={`folderId-${taxFormFolder.folderId}`}
                  >
                    {this.getSubFolderList(taxFormFolder)}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            background: "#fff",
            border: ".1rem solid #dfdfdf",
            margin: "0 20px 8px 0",
            borderBottomRightRadius: ".5rem",
            borderTopLeftRadius: ".5rem",
            padding: "6px 30px",
          }}
        >
          {this.props.selectedTaxFormsCount}/{this.props.totalTaxFormsCount}{" "}
          forms selected
        </div>
      </div>
    );
  }

  getSubFolderList = folder => {
    return map(get(folder, "taxForms", []), taxForm => {
      return (
        <div
          style={{ margin: "6px 15px 6px 63px" }}
          key={`${taxForm.name}-${taxForm.pages[0].pageNumber || 0}`}
        >
          <label className="cps-checkbox">
            <input
              type="checkbox"
              checked={this.props.isRowSelected(taxForm.name)}
              onClick={() => {
                this.props.onTaxFormRowClick(taxForm.name);
              }}
            />
            <span>{taxForm.name}</span>
          </label>
        </div>
      );
    });
  };
}
