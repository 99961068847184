import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import TestMenu from "./test-menu/test-menu.component.js";

export default class Root extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Route path="/testing" component={TestMenu} />
      </BrowserRouter>
    )
  }
}
