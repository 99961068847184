import React from "react";
import { catchAsyncStacktrace } from "auto-trace";
import { findIndex, isEmpty, orderBy } from "lodash";
import Cancelable from "react-disposable-decorator";
import * as moment from "moment";
import { Scoped, k } from "kremling";

import {
  getRootDirectory,
  getDirectory,
  getSearchResults,
  getAllFailedTests,
} from "../sme-qa-ui.resource.js";
import CreateTest from "./create-test/create-test.component.js";
import ManageTests from "./manage-tests/manage-tests.component.js";
import UploadConversions from "./upload-conversions/upload-conversions.component.js";
import AutomatedTestsHeader from "../common/automated-tests-header.component";

@Cancelable
export default class TestModal extends React.Component {
  constructor(props) {
    super(props);

    let revision = this.props.revisionId;
    let version = this.props.versionId;
    let activeTab = "manage";

    if (this.props.persistingRevision) {
      revision = this.props.persistingRevision;
    }

    if (this.props.persitingVersion) {
      version = this.props.persitingVersion;
    }

    if (this.props.persistingActiveTab) {
      activeTab = this.props.persistingActiveTab;
    }

    this.state = {
      activeTab,
      search: "",
      searchResults: null,
      directory: [],
      parentId: "",
      showDirectory: false,
      newFolder: false,
      breadcrumbs: [],
      showOnlyFailedTests: false,
      sortColumn: "name",
      sortDirection: "asc",
      ogRevisionId: this.props.revisionId,
      ogVersionId: this.props.versionId,
      revisionId: revision,
      versionId: version,
      location: "",
    };
  }

  componentDidMount() {
    if (this.props.persistedLocationId) {
      this.callGetDirectory(this.props.persistedLocationId);
    } else {
      this.callGetRootDirectory();
    }

    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.body.style.overflow = "visible";

    if (this.getSearchResultsObservable) {
      this.getSearchResultsObservable.unsubscribe();
      this.getSearchResultsObservable = null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.versionId !== this.state.versionId) {
      this.callGetRootDirectory();
    } else if (prevState.revisionId !== this.state.revisionId) {
      if (this.state.location) this.callGetDirectory(this.state.location);
      else this.callGetRootDirectory();
    }
  }

  render() {
    return (
      <div className="cps-modal">
        <div
          className="cps-modal__screen"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.displayModal()}
        </div>
      </div>
    );
  }

  setActiveTab = activeTab => {
    this.props.setPersistingActiveTab(activeTab);
    this.setState({ activeTab });
  };

  callGetRootDirectory = () => {
    const {
      activeTab,
      versionId,
      revisionId,
      ogRevisionId,
      ogVersionId,
    } = this.state;
    const version = activeTab === "create" ? ogVersionId : versionId;
    const revision = activeTab === "create" ? ogRevisionId : revisionId;
    this.setState({ showDirectory: false });
    this.props.setPersistingLocationId(null);
    if (this.state.showOnlyFailedTests) {
      this.loadAllFailedTests();
    } else {
      this.props.cancelWhenUnmounted(
        getRootDirectory(version, revision).subscribe(dir => {
          const directory = dir.folders.map(folder => ({
            ...folder,
            isTest: false,
            date: moment.unix(folder.createdAt / 1000).format("MM/DD/YYYY"),
            sortName: folder.name.toLowerCase(),
          }));
          this.setState({
            directory: this.sortDirectory(directory),
            showDirectory: true,
            breadcrumbs: [],
            parentId: "",
            search: "",
            searchResults: null,
            location: "",
            certifiable: null,
          });
        }, catchAsyncStacktrace())
      );
    }
  };

  callGetDirectory = id => {
    const {
      activeTab,
      versionId,
      revisionId,
      ogRevisionId,
      ogVersionId,
    } = this.state;
    const version = activeTab === "create" ? ogVersionId : versionId;
    const revision = activeTab === "create" ? ogRevisionId : revisionId;
    this.setState({ showDirectory: false });
    this.props.setPersistingLocationId(id);
    if (this.state.showOnlyFailedTests) {
      this.loadAllFailedTests(id);
    } else {
      this.props.cancelWhenUnmounted(
        getDirectory(version, revision, id).subscribe(dir => {
          const folders = dir.folders.map(folder => ({
            ...folder,
            isTest: false,
          }));
          const tests = dir.tests.map(test => ({ ...test, isTest: true }));
          const directory = [...folders, ...tests].map(item => ({
            ...item,
            date: moment.unix(item.createdAt / 1000).format("MM/DD/YYYY"),
            sortName: item.name.toLowerCase(),
          }));
          const certifiable = dir.runNightly || this.state.certifiable;
          this.setState({
            directory: this.sortDirectory(directory),
            breadcrumbs: dir.path,
            parentId: id,
            search: "",
            searchResults: null,
            showDirectory: true,
            location: id,
            certifiable,
          });
        }, catchAsyncStacktrace())
      );
    }
  };

  callGetSearchResults = search => {
    const {
      activeTab,
      parentId,
      showOnlyFailedTests,
      versionId,
      revisionId,
      ogRevisionId,
      ogVersionId,
    } = this.state;
    const version = activeTab === "create" ? ogVersionId : versionId;
    const revision = activeTab === "create" ? ogRevisionId : revisionId;
    this.setState({ showDirectory: false });

    if (this.getSearchResultsObservable) {
      this.getSearchResultsObservable.unsubscribe();
      this.getSearchResultsObservable = null;
    }

    this.getSearchResultsObservable = getSearchResults(
      version,
      revision,
      search,
      parentId,
      showOnlyFailedTests
    ).subscribe(dir => {
      const folders = dir.folders
        ? dir.folders.map(folder => ({
            ...folder,
            isTest: false,
          }))
        : [];
      const tests = dir.tests.map(test => ({ ...test, isTest: true }));
      const directory = [...folders, ...tests].map(item => ({
        ...item,
        date: moment.unix(item.createdAt / 1000).format("MM/DD/YYYY"),
        sortName: item.name.toLowerCase(),
      }));
      this.setState({
        searchResults: this.sortDirectory(directory),
        showDirectory: true,
      });
    }, catchAsyncStacktrace());
  };

  loadAllFailedTests = id => {
    const { versionId, revisionId } = this.state;
    this.props.cancelWhenUnmounted(
      getAllFailedTests(versionId, revisionId, id).subscribe(dir => {
        const tests = dir.tests.map(test => ({
          ...test,
          isTest: true,
          date: moment.unix(test.createdAt / 1000).format("MM/DD/YYYY"),
          sortName: test.name.toLowerCase(),
        }));
        this.setState(prevState => {
          const breadcrumbIndex = findIndex(
            prevState.breadcrumbs,
            b => b.id === id
          );
          const breadcrumbs = prevState.breadcrumbs.slice(
            breadcrumbIndex,
            prevState.breadcrumbs.length
          );
          return {
            directory: this.sortDirectory(tests),
            showDirectory: true,
            breadcrumbs: id ? breadcrumbs : [],
            parentId: id ? breadcrumbs[0].id : "",
          };
        });
      })
    );
  };

  displayModal = () => {
    const {
      activeTab,
      breadcrumbs,
      directory,
      newFolder,
      parentId,
      revisionId,
      search,
      searchResults,
      showDirectory,
      showOnlyFailedTests,
      versionId,
      certifiable,
      ogRevisionId,
      ogVersionId,
    } = this.state;

    if (this.props.type === "automatedTests") {
      return (
        <Scoped css={css}>
          <div className="cps-card">
            <AutomatedTestsHeader
              onCompleted={this.props.onCompleted}
              setActiveTab={this.setActiveTab}
              activeTab={activeTab}
              versions={this.props.versions}
              versionId={versionId}
              revisionId={revisionId}
              ogVersionId={ogVersionId}
              ogRevisionId={ogRevisionId}
              updateVersionAndRevision={this.updateVersionAndRevision}
            />
            {activeTab === "create" ? (
              <CreateTest
                onCompleted={this.props.onCompleted}
                createFolder={this.createFolder}
                newFolder={newFolder}
                addItem={this.addItem}
                cancelFolder={this.cancelFolder}
                breadcrumbs={breadcrumbs}
                callGetDirectory={this.callGetDirectory}
                callGetRootDirectory={this.callGetRootDirectory}
                showDirectory={showDirectory}
                directory={directory}
                parentId={parentId}
                versionId={ogVersionId}
                revisionId={ogRevisionId}
                engagementId={this.props.engagementId}
                clientId={this.props.clientId}
                folderIds={this.props.folderIds}
                taxFormType={this.props.taxFormType}
                taxFormName={this.props.taxFormName}
                search={search}
                searchResults={searchResults}
                editSearch={this.editSearch}
                clearSearch={this.clearSearch}
              />
            ) : (
              <ManageTests
                onCompleted={this.props.onCompleted}
                createFolder={this.createFolder}
                newFolder={newFolder}
                addItem={this.addItem}
                editItem={this.editItem}
                deleteItem={this.deleteItem}
                cancelFolder={this.cancelFolder}
                breadcrumbs={breadcrumbs}
                callGetDirectory={this.callGetDirectory}
                callGetRootDirectory={this.callGetRootDirectory}
                showDirectory={showDirectory}
                directory={directory}
                parentId={parentId}
                versionId={versionId}
                revisionId={revisionId}
                search={search}
                searchResults={searchResults}
                editSearch={this.editSearch}
                clearSearch={this.clearSearch}
                toggleFailedTests={this.toggleFailedTests}
                showOnlyFailedTests={showOnlyFailedTests}
                handleSortClick={this.handleSortClick}
                refetchSearch={this.refetchSearch}
                certifiable={certifiable}
                taskId={this.props.taskId}
                parentId={parentId}
                clientId={this.props.clientId}
                engagementId={this.props.engagementId}
                onCompleted={this.props.onCompleted}
              />
            )}
          </div>
        </Scoped>
      );
    } else if (this.props.type === "conversions") {
      return (
        <UploadConversions
          onCompleted={this.props.onCompleted}
          clientId={this.props.clientId}
          versionId={ogVersionId}
          revisionId={ogRevisionId}
          engagementId={this.props.engagementId}
        />
      );
    }
  };

  toggleFailedTests = bool => {
    if (bool !== this.state.showOnlyFailedTests) {
      this.setState({ showDirectory: false, showOnlyFailedTests: bool }, () => {
        if (!isEmpty(this.state.searchResults)) {
          this.callGetSearchResults(this.state.search);
        } else if (bool) {
          this.loadAllFailedTests(this.state.parentId);
        } else if (this.state.parentId) {
          this.callGetDirectory(this.state.parentId);
        } else {
          this.callGetRootDirectory();
        }
      });
    }
  };

  editSearch = evt => {
    const search = evt.target.value;
    const trimmedSearch = search.trim();
    if (trimmedSearch.length > 2) {
      this.callGetSearchResults(trimmedSearch);
    }
    this.setState(prevState => {
      return {
        search,
        searchResults: trimmedSearch ? prevState.searchResults : null,
      };
    });
  };

  clearSearch = () => {
    this.setState(prevState => {
      return {
        search: "",
        searchResults: null,
      };
    });
  };

  refetchSearch = () => this.callGetSearchResults(this.state.search);

  createFolder = () => {
    if (!this.state.search) {
      this.setState({ newFolder: true });
    }
  };

  addItem = item => {
    const newItem = {
      ...item,
      isTest: item.hasOwnProperty("dateRan"),
      date: moment.unix(item.createdAt / 1000).format("MM/DD/YYYY"),
      sortName: item.name.toLowerCase(),
    };
    this.setState(prevState => {
      return {
        directory: this.sortDirectory([newItem, ...prevState.directory]),
        newFolder: false,
      };
    });
  };

  editItem = item => {
    const editItem = {
      ...item,
      isTest: item.hasOwnProperty("dateRan"),
      date: moment.unix(item.createdAt / 1000).format("MM/DD/YYYY"),
      sortName: item.name.toLowerCase(),
    };

    if (!isEmpty(this.state.searchResults)) {
      this.setState(prevState => {
        const searchResults = prevState.searchResults.map(i => {
          return i.id === editItem.id ? editItem : i;
        });
        return { searchResults };
      });
    } else {
      this.setState(prevState => {
        const directory = prevState.directory.map(i => {
          return i.id === editItem.id ? editItem : i;
        });
        return { directory };
      });
    }
  };

  deleteItem = item => {
    if (!isEmpty(this.state.searchResults)) {
      this.setState(prevState => {
        return {
          searchResults: prevState.searchResults.filter(i => i.id !== item.id),
        };
      });
    } else {
      this.setState(prevState => {
        return { directory: prevState.directory.filter(i => i.id !== item.id) };
      });
    }
  };

  cancelFolder = () => {
    this.setState({ newFolder: false });
  };

  sortDirectory = (directory, column, direction) => {
    let folders = [];
    let tests = [];
    directory.forEach(i => {
      if (i.isTest) {
        tests.push(i);
      } else {
        folders.push(i);
      }
    });

    const sortColumn = column
      ? column
      : [test => test[this.state.sortColumn].toLowerCase()];
    const sortDirection = direction ? direction : this.state.sortDirection;

    const sortedFolders = orderBy(folders, sortColumn, sortDirection);
    const sortedTests = orderBy(tests, sortColumn, sortDirection);
    return [...sortedFolders, ...sortedTests];
  };

  handleSortClick = (column, direction) => {
    this.setState(prevState => {
      return {
        sortColumn: column,
        sortDirection: direction,
        directory: this.sortDirectory(prevState.directory, column, direction),
      };
    });
  };

  updateVersionAndRevision = (versionId, revisionId) => {
    this.props.setPersistingVersionAndRevision(versionId, revisionId);
    this.setState({ versionId, revisionId, certifiable: null });
  };
}

const css = k`
  .cps-card {
    width: 130rem;
    height: 78.7rem;
    border-radius: 5px;
  }
`;
