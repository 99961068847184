import React, { Component } from "react";
import { string, array } from "prop-types";
import { Scoped, k } from "kremling";
import {
  CpButton,
  CpIcon,
  CpDropdown,
} from "canopy-styleguide!sofe";

const VERSION = "VERSION";
const REVISION = "REVISION";

export class VersionSelector extends Component {
  static propTypes = {
    versionId: string,
    revisionId: string,
    versionList: array,
  };

  state = {
    view: REVISION,
    userSelectedVersion: null,
  };

  changeView = view => {
    this.setState({ view });
  };

  selectVersion = userSelectedVersion => {
    this.setState({ userSelectedVersion, view: REVISION });
  };

  useRevisions = () => {
    const { userSelectedVersion } = this.state;
    const { versionList, versionId } = this.props;
    if (userSelectedVersion) return userSelectedVersion.revisions;
    return versionList.find(v => v.id === versionId).revisions;
  };

  setVersionAndRevision = selectedRevisionId => {
    const { userSelectedVersion } = this.state;
    const { versionId, revisionId } = this.props;
    if (
      revisionId !== selectedRevisionId ||
      userSelectedVersion !== versionId
    ) {
      const selectedVersionId =
        (userSelectedVersion && userSelectedVersion.id) || versionId;
      this.props.updateVersionAndRevision(
        selectedVersionId,
        selectedRevisionId
      );
    }
  };

  renderDropDownTrigger = revisionName => {
    return (
      <div className="version-select-dropdown">
        {revisionName}
        <CpIcon
          name="caret-small-down"
          style={{ padding: "0 8px 4px 16px" }}
        />
      </div>
    );
  };

  render() {
    const { view, userSelectedVersion } = this.state;
    const { versionList, versionId, revisionId } = this.props;
    const selectedVersion = versionList.find(v => v.id === versionId);
    const selectedRevision =
      selectedVersion &&
      selectedVersion.revisions.find(r => r.id === revisionId);
    return (
      <Scoped css={css}>
        {!!versionList.length ? (
          <div className="version-selector">
            <CpDropdown
              size="md"
              position="bottom-left"
              allowContentClicks
              onClose={() => this.changeView(REVISION)}
              renderTrigger={() => this.renderDropDownTrigger(selectedRevision.name)}
              renderContent={props => (
                <div>
                  <div className="version-selector__head">
                    {view === REVISION && (
                      <>
                        <CpButton
                          icon="caret-large-left"
                          onClick={() => this.changeView(VERSION)}
                        />
                        {userSelectedVersion
                          ? userSelectedVersion.name
                          : selectedVersion.name}
                      </>
                    )}
                    {view === VERSION && "Select Version"}
                  </div>
                  {view === REVISION &&
                    this.useRevisions().map(revision => (
                      <div
                        className="dropdown-item"
                        key={revision.id}
                        onClick={() => {
                          props.close();
                          this.setVersionAndRevision(revision.id);
                        }}
                      >
                        <div>{revision.name}</div>
                        {revision.id === selectedRevision.id &&
                          revision.version === selectedVersion.id && (
                            <CpIcon
                              className="select-list__selected"
                              name="checkmark-circle-filled-small"
                            />
                          )}
                      </div>
                    ))}
                  {view === VERSION &&
                    versionList.map(version => (
                      <div
                        className="dropdown-item"
                        key={version.id}
                        onClick={() => this.selectVersion(version)}
                      >
                        {version.name}
                      </div>
                    ))}
                </div>
              )}
            />
          </div>
        ) : (
          <CpButton> -- </CpButton>
        )}
      </Scoped>
    );
  }
}

const css = k`
  .version-selector__head {
    padding: .8rem;
    display: flex;
    align-items: center;
    font-weight: 700;
    border-bottom: solid .1rem var(--cps-color-athens);
    height: 4rem;
  }
  
  .version-selector__head button {
    margin-right: .8rem;
  }
  
  .version-select-dropdown {
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    padding: 2px 0 2px 24px;
    cursor: default;
  }

  .version-select-dropdown:hover {
    background-color: var(--cps-color-chrome);
  }

  .dropdown-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
  }

  .dropdown-item:hover {
    color: var(--cps-color-bumble);
    background-color: var(--cps-color-vibrant-ocean);
  }

  .select-list__selected {
    margin-top: -2px;
  }

  .dropdown-trigger {
    margin-top: 4px;
  }
`;
