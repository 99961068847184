import React from "react";
import { Scoped, a, m, k } from "kremling";
import Cancelable from "react-disposable-decorator";
import { capitalize, get, isEmpty } from "lodash";
import { successToast, infoToast } from "toast-service!sofe";
import { catchAsyncStacktrace, catchSyncStacktrace } from "auto-trace";
import {
  createFolder,
  editFolder,
  editTest,
  deleteFolder,
  deleteTest,
  restoreFolder,
  restoreTest,
  runFolderTests,
  runTest,
  getTest,
  updateEngagement,
} from "../../sme-qa-ui.resource.js";
import DragAndDropWrapper from "../../common/drag-and-drop-wrapper.component.js";
import ManageTestActions from "./manage-tests-actions.component.js";
import { Loader } from "../../ui/loader.component";

@Cancelable
export default class ManageTestsDirectoryBody extends React.Component {
  state = {
    newFolderName: "",
    editItemName: "",
    editItemId: null,
    displayedFoldersAndTests: [],
    loading: false,
  };

  componentDidUpdate() {
    if (this.props.newFolder) {
      this.el && this.el.focus();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !nextProps.searchResults &&
      prevState.displayedFoldersAndTests !== nextProps.directory
    ) {
      return { displayedFoldersAndTests: nextProps.directory };
    } else if (
      nextProps.searchResults &&
      prevState.displayedFoldersAndTests !== nextProps.searchResults
    ) {
      return { displayedFoldersAndTests: nextProps.searchResults };
    } else {
      return null;
    }
  }

  cancelClick = () => {
    this.setState({ newFolderName: "" }, this.props.cancelFolder);
  };

  handlePullTest = test => {
    const {
      engagementId,
      clientId,
      revisionId,
      taskId,
      versionId,
    } = this.props;

    this.setState({ isPulling: true });
    this.props.cancelWhenUnmounted(
      updateEngagement(
        versionId,
        revisionId,
        engagementId,
        clientId,
        test.id
      ).subscribe(
        res => {
          this.props.onCompleted();
          const isTaxPrep = window.location.hash.indexOf("tax-prep-tasks");
          if (isTaxPrep > -1) {
            window.location.hash = `/tax-prep-tasks/${taskId}/prepare?smeQaTestName=${encodeURIComponent(
              test.name
            )}`;
          } else {
            window.location.hash = `/taxes/client/${clientId}/engagements/${engagementId}/summary?smeQaTestName=${encodeURIComponent(
              test.name
            )}`;
          }
        },
        () => this.setState({ isPulling: false }, catchSyncStacktrace())
      )
    );
  };

  handleOverwriteTest = test => {
    const {
      engagementId,
      versionId,
      revisionId,
      parentId,
      clientId,
    } = this.props;

    this.setState({ loading: true });
    this.props.cancelWhenUnmounted(
      editTest(
        versionId,
        revisionId,
        test,
        parentId,
        engagementId,
        clientId
      ).subscribe(
        test => {
          successToast(`${test.name} was successfully overwritten!`);
          this.props.onCompleted();
        },
        () => this.setState({ loading: false }, catchSyncStacktrace())
      )
    );
  };

  handleItemClick(item) {
    if (item.isTest) {
      const { versionId, revisionId } = this.props;
      this.props.addSelectedTest({ ...item, isLoaded: false });
      this.props.cancelWhenUnmounted(
        getTest(versionId, revisionId, item.id).subscribe(test => {
          const failedResults = get(test[0], "failedResults", []);
          const errorCount = get(test[0], "errorCount", []);
          this.props.addSelectedTest({
            ...item,
            failedResults,
            errorCount,
            isLoaded: true,
          });
        }, catchAsyncStacktrace())
      );
    } else {
      this.props.callGetDirectory(item.id);
    }
  }

  handleChange = evt => {
    this.setState({ newFolderName: evt.target.value });
  };

  handleAddSubmit = evt => {
    const { versionId, revisionId, parentId } = this.props;
    const { newFolderName } = this.state;
    if (newFolderName.trim() && get(evt, "relatedTarget.tabIndex") !== 1) {
      this.props.cancelWhenUnmounted(
        createFolder(
          versionId,
          revisionId,
          newFolderName.trim(),
          parentId
        ).subscribe(folder => {
          this.setState({ newFolderName: "" }, () =>
            this.props.addItem(folder)
          );
        }, this.handleError)
      );
    }
  };

  postEditLogic = editablePayload => {
    this.setState({ editItemName: "", editItemId: null }, () => {
      this.props.editItem(editablePayload);
      this.props.searchResults && this.props.refetchSearch();
    });
  };

  handleEditSubmit(item) {
    const { versionId, revisionId, parentId } = this.props;
    const { editItemName } = this.state;
    if (editItemName.trim() && item.name !== editItemName.trim()) {
      const newItem = { ...item, name: editItemName };
      if (newItem.isTest) {
        const parent = parentId || newItem.folderParentId;
        this.props.cancelWhenUnmounted(
          editTest(versionId, revisionId, newItem, parent).subscribe(test => {
            this.postEditLogic(test);
          }, this.handleError)
        );
      } else {
        this.props.cancelWhenUnmounted(
          editFolder(versionId, revisionId, newItem, parentId).subscribe(
            folder => {
              this.postEditLogic(folder);
            },
            this.handleError
          )
        );
      }
    } else {
      this.setState({ editItemName: "", editItemId: null });
    }
  }

  handleEditInput = evt => {
    this.setState({ editItemName: evt.target.value });
  };

  handleEditClick = item => {
    this.setState({ editItemName: item.name, editItemId: item.id }, () =>
      this.directoryInputs[item.id].focus()
    );
  };

  handleCertification = item => {
    const { versionId, revisionId, parentId } = this.props;
    const newItem = { ...item, certified: !item.certified };

    const parent = parentId || newItem.folderParentId;
    this.props.cancelWhenUnmounted(
      editTest(versionId, revisionId, newItem, parent).subscribe(test => {
        this.postEditLogic(test);
      }, this.handleError)
    );
  };

  handleRunNightly = item => {
    const { versionId, revisionId, parentId } = this.props;
    const newItem = { ...item, runNightly: !item.runNightly };

    this.props.cancelWhenUnmounted(
      editFolder(versionId, revisionId, newItem, parentId).subscribe(folder => {
        this.postEditLogic(folder);
      }, this.handleError)
    );
  };

  handleRestoreFolder(folder) {
    const { versionId, revisionId } = this.props;
    this.props.cancelWhenUnmounted(
      restoreFolder(versionId, revisionId, folder).subscribe(
        res => {
          this.props.addItem(folder);
        },
        err => catchAsyncStacktrace()
      )
    );
  }

  handleRestoreTest(test) {
    const { versionId, revisionId } = this.props;
    this.props.cancelWhenUnmounted(
      restoreTest(versionId, revisionId, test).subscribe(
        res => {
          this.props.addItem(test);
        },
        err => catchAsyncStacktrace()
      )
    );
  }

  handleDeleteClick = item => {
    const { versionId, revisionId } = this.props;
    if (item.isTest) {
      this.props.cancelWhenUnmounted(
        deleteTest(versionId, revisionId, item).subscribe(
          res => this.handleDeleteSuccess(res, item),
          this.handleError
        )
      );
    } else {
      this.props.cancelWhenUnmounted(
        deleteFolder(versionId, revisionId, item).subscribe(
          res => this.handleDeleteSuccess(res, item),
          this.handleError
        )
      );
    }
  };

  handleError = err => {
    this.setState({ editItemName: "", editItemId: null, newFolderName: "" });
    if (get(err, "data.errors.httpStatusCode") === 409) {
      const messageStr = get(err, "data.errors.message");
      const message = messageStr.slice(messageStr.indexOf("|") + 1);
      infoToast(message);
    } else {
      catchSyncStacktrace();
    }
  };

  handleDeleteSuccess(res, item) {
    if (res.status === "success") {
      this.props.deleteItem(item);
      successToast(
        `${item.name} was successfully deleted!`,
        "undo",
        item.isTest
          ? () => this.handleRestoreTest(item)
          : () => this.handleRestoreFolder(item),
        5000
      );
    }
  }

  handleRunClick(item) {
    const { versionId, revisionId } = this.props;
    if (item.isTest) {
      this.props.editItem({ ...item, status: "running" });
      this.props.cancelWhenUnmounted(
        runTest(versionId, revisionId, item.id).subscribe(test => {
          this.props.editItem({ ...item, status: test.status });
        }, catchAsyncStacktrace())
      );
    } else {
      this.props.editItem({ ...item, status: "running" });
      this.props.cancelWhenUnmounted(
        runFolderTests(versionId, revisionId, item.id).subscribe(
          ({ failedCount, passedCount, status }) => {
            this.props.editItem({ ...item, failedCount, passedCount, status });
          },
          catchAsyncStacktrace()
        )
      );
    }
  }

  showEmptyState = () => {
    if (this.props.searchResults && this.props.searchResults.length === 0) {
      return (
        <div className="empty-state">
          <img src="https://s3-us-west-2.amazonaws.com/cdn.canopytax.com/images/empty-search.png" />
        </div>
      );
    } else if (!this.props.newFolder) {
      return (
        <div className="empty-state">
          <img src="https://s3-us-west-2.amazonaws.com/cdn.canopytax.com/images/empty-folder.png" />
        </div>
      );
    }
    return null;
  };

  directoryInputs = {};

  render() {
    const filter = this.props.filter;
    return (
      <Scoped css={css}>
        <div className="folder-container">
          {this.props.showDirectory && this.props.newFolder && (
            <div className="cps-flex-table__row">
              <div className="cps-flex-table__col new-folder-container">
                <i className="cps-icon cps-icon-folder new-folder-icon" />
                <span className="cps-icon cps-icon-add cps-white new-folder-add" />
                <input
                  className="cps-form-control new-folder-input"
                  value={this.state.newFolderName}
                  onChange={this.handleChange}
                  onBlur={this.handleAddSubmit}
                  onKeyPress={e => e.key === "Enter" && this.handleAddSubmit()}
                  ref={el => (this.el = el)}
                />
                <span
                  className="cps-link"
                  onClick={this.cancelClick}
                  tabIndex="1"
                >
                  Cancel
                </span>
              </div>
            </div>
          )}
          {this.props.showDirectory && !this.state.loading ? (
            !isEmpty(this.state.displayedFoldersAndTests) ? (
              this.state.displayedFoldersAndTests
                .filter(item => {
                  return (
                    item.name
                      .toLowerCase()
                      .indexOf(filter.name.toLowerCase()) >= 0 &&
                    item.date.indexOf(filter.date) >= 0 &&
                    item.status
                      .toLowerCase()
                      .indexOf(filter.status.toLowerCase()) >= 0
                  );
                })
                .map(item => {
                  const passedCount = get(item, "passedCount", 0);
                  const failedCount = get(item, "failedCount", 0);
                  const totalTestCount = failedCount + passedCount;
                  const failPassTotalTestCount = `${passedCount}/${totalTestCount}`;

                  return (
                    <DragAndDropWrapper
                      key={item.id}
                      isDropZone={item.isTest ? false : true}
                      dragEnter={() => this.props.dragEnter(item)}
                      dragLeave={this.props.dragLeave}
                    >
                      <div className="cps-flex-table__row existing-rows">
                        <div
                          className={a("cps-flex-table__col").toggle(
                            "folder-name-container-sm",
                            "folder-name-container-lrg",
                            this.props.sidePanelOpen
                          )}
                          onClick={() => this.handleItemClick(item)}
                        >
                          <i
                            className={a("cps-icon")
                              .m("cps-icon-folder", !item.isTest)
                              .m(
                                "cps-icon-files",
                                item.isTest && isEmpty(item.taxFormNames)
                              )
                              .m(
                                "cps-icon-pdf-file",
                                item.isTest && !isEmpty(item.taxFormNames)
                              )}
                          />
                          <span
                            className={a("directory-row-text").m(
                              "cps-hidden",
                              this.state.editItemId === item.id
                            )}
                            title={item.name}
                          >
                            {item.name}
                          </span>
                          {totalTestCount > 0 && (
                            <span className="total-test-count">
                              {failPassTotalTestCount}
                            </span>
                          )}
                          <input
                            className={a("cps-form-control edit-input").m(
                              "cps-hidden",
                              this.state.editItemId !== item.id
                            )}
                            type="text"
                            value={this.state.editItemName}
                            onChange={this.handleEditInput}
                            ref={el => (this.directoryInputs[item.id] = el)}
                            onBlur={() => this.handleEditSubmit(item)}
                            onKeyPress={e =>
                              e.key === "Enter" && this.handleEditSubmit(item)
                            }
                            onClick={e => e.stopPropagation()}
                          />
                        </div>
                        {!this.props.sidePanelOpen && (
                          <>
                            <div
                              className={a(
                                "cps-flex-table__col cursor-pointer"
                              ).m("empty-status", !item.isTest)}
                              onClick={() => this.handleItemClick(item)}
                            >
                              <span className="directory-row-text">
                                {item.isTest
                                  ? isEmpty(item.taxFormNames)
                                    ? "Full"
                                    : "Partial"
                                  : "..."}
                              </span>
                            </div>
                            <div
                              className="cps-flex-table__col cursor-pointer"
                              onClick={() => this.handleItemClick(item)}
                            >
                              <span className="directory-row-text">
                                {item.date}
                              </span>
                            </div>
                          </>
                        )}
                        <div
                          className={a("cps-flex-table__col cursor-pointer").m(
                            "status-center",
                            this.props.sidePanelOpen
                          )}
                          onClick={() => this.handleItemClick(item)}
                        >
                          <i
                            className={a("cps-icon")
                              .m(
                                "cps-icon-sm-neg cps-red",
                                item.status === "failed" ||
                                  item.status === "error"
                              )
                              .m(
                                "cps-icon-sm-check cps-green",
                                item.status === "passed"
                              )
                              .m("cps-icon-recent", item.status === "running")}
                          />
                          <span
                            className={a("directory-row-text")
                              .m(
                                "cps-hidden",
                                this.props.sidePanelOpen && item.status
                              )
                              .m(
                                "cps-red",
                                item.status === "failed" ||
                                  item.status === "error"
                              )
                              .m("cps-green", item.status === "passed")}
                          >
                            {item.status ? (
                              (item.status === "failed" && item.failedCount
                                ? item.failedCount + " "
                                : "") + capitalize(item.status)
                            ) : (
                              <span
                                className={m(
                                  "empty-status",
                                  !this.props.sidePanelOpen
                                )}
                              >
                                --
                              </span>
                            )}
                          </span>
                        </div>
                        <div
                          className={a(
                            "cps-flex-table__col action-container"
                          ).m(
                            "badge-margin",
                            item.runNightly || item.certified
                          )}
                        >
                          {item.status === "running" ? (
                            <div className="run-loader">
                              <Loader color="rainbow" />
                            </div>
                          ) : (
                            <div
                              className="cps-link run-test"
                              onClick={() => this.handleRunClick(item)}
                              role="button"
                              tabIndex="0"
                            >
                              Run
                            </div>
                          )}
                        </div>
                        <ManageTestActions
                          certifiable={this.props.certifiable}
                          isRootFolder={this.props.isRootFolder}
                          item={item}
                          handleCertification={this.handleCertification}
                          handleDeleteClick={this.handleDeleteClick}
                          handleEditClick={this.handleEditClick}
                          handleMoveClick={this.props.handleMoveClick}
                          handleOverwriteTest={this.handleOverwriteTest}
                          handlePullTest={this.handlePullTest}
                          handleRunNightly={this.handleRunNightly}
                        />
                      </div>
                    </DragAndDropWrapper>
                  );
                })
            ) : (
              this.showEmptyState()
            )
          ) : (
            <Loader color="rainbow" size="large" overlay />
          )}
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .new-folder-container {
    background-color: var(--cps-color-ash);
  }

  .new-folder-icon {
    color: #00bf4b;
  }

  .new-folder-input {
    border-color: var(--cps-color-ash);
    background-color: var(--cps-color-ash);
    position: relative;
    right: 28px;
    padding-left: 3px;
  }

  .new-folder-input:focus {
    box-shadow: none !important;
  }

  .new-folder-add {
    right: 34px;
    top: 2px;
  }

  .total-test-count {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    width: fit-content;
    height: 25px;
    padding: 0 5px;
    background-color: var(--cps-color-ash);
    border: 1px solid var(--cps-color-athens);
    border-radius: 4px;
  }

  .cps-flex-table__col {
    display: flex;
    align-items: center;
    min-width: 120px;
  }

  .existing-rows {
    overflow: visible;
  }
  
  .cps-flex-table__row:hover {
    background-color: var(--cps-color-ash);
  }

  .cps-icon-folder {
    margin-right: 16px;
  }

  .cps-icon-files {
    margin-right: 16px;
  }

  .cps-icon-pdf-file {
    margin-right: 16px;
  }

  .cps-icon-recent {
    font-size: 1.7rem;
    margin: 0 4px;
  }

  .directory-row-text {
    font-size: 14px;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .folder-container {
    height: 520px;
    overflow: auto;
    padding-bottom: 30px;
    position: relative;
  }

  .folder-name-container-sm {
    cursor: pointer;
    min-width: 270px;
  }

  .folder-name-container-lrg {
    cursor: pointer;
    min-width: 625px;
  }

  .action-container {
    min-width: 125px;
  }

  .run-test {
    cursor: pointer;
    color: #00bf4b;
    font-size: 14px;
    font-weight: bold;
  }

  .run-loader {
    padding: 4px 0;
  }

  .status-center {
    justify-content: center;
  }

  .empty-status {
    padding-left: 32px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cps-icon-action {
    background-color: #f2f2f2;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 0.4rem;
  }

  .cps-icon-action:hover {
    background-color: #d4d4d4;
  }

  .empty-state {
    display: flex;
    justify-content: center;
    padding-top: 72px;
  }

  .row-actions {
    height: 100%;
    display: flex;
    align-items: center;
    right: 0;
    position: absolute;
    padding-right: 2.4rem;
  }

  .badge-margin {
    margin-right: -32px;
  }
`;
