import React from "react";
import { Scoped, always, k } from "kremling";
import CanopySelect from "cpr-select";
import { get } from "lodash";

export default class ManageTestsOptions extends React.Component {
  render() {
    return (
      <Scoped css={css}>
        <div className="options-container">
          <div className="options-left">
            <span
              className={always("options-text").maybe(
                "options-text-selected",
                !this.props.showOnlyFailedTests
              )}
              onClick={() => this.props.toggleFailedTests(false)}
            >
              All tests
            </span>
            <span
              className={always("options-text").maybe(
                "options-text-selected",
                this.props.showOnlyFailedTests
              )}
              onClick={() => this.props.toggleFailedTests(true)}
            >
              Failed
            </span>
          </div>
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .options-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 2.4rem;
    border-bottom: 0.1rem solid #e9e9e9;
  }

  .options-left {
    width: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .options-text {
    font-size: 16px;
    padding: 4px;
    cursor: pointer;
  }

  .options-text-selected {
    font-weight: bold;
    border-bottom: 2px solid #00bf4b;
  }

  .cps-icon-new-folder:hover {
    text-decoration: none;
  }

  .cps-icon-new-folder {
    font-size: 26px;
  }
`;
