import React from "react";
import { Scoped, k } from "kremling";

export default class CreateTestName extends React.Component {
  render() {
    const { testName } = this.props;

    return (
      <Scoped css={css}>
        <div className="add-name-container">
          <div className="test-name-field">
            <span className="cps-body">Enter test name</span>
            <input
              type="text"
              className="cps-form-control add-name-input"
              placeholder="e.g. Sch 1 line 25 HSA deduction "
              value={testName}
              onChange={this.props.editTestName}
            />
          </div>
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .add-name-container {
    padding: 1.2rem 2.4rem;
    display: flex;
    align-items: center;
    border-top: 0.1rem solid var(--cps-color-silver);
    color: #4a4a4a;
    justify-content: space-between;
  }

  .test-name-field {
    display: flex;
    align-items: center;
  }

  .add-name-input {
    margin: 0 20px 0 20px;
    width: 320px;
    border-color: var(--cps-color-chrome);
    background-color: var(--cps-color-chrome);
  }

  .add-name-input:focus {
    box-shadow: none !important;
  }
`;
