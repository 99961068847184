import React from "react";
import { Scoped, always, toggle, k } from "kremling";

export default class ManageTestsDirectoryHeader extends React.Component {
  state = {
    isFilterOpen: false,
  };

  handleFilterClick = () => {
    this.setState(prevState => ({ isFilterOpen: !prevState.isFilterOpen }));
  };

  render() {
    return (
      <Scoped css={css}>
        <div className="cps-flex-table__header">
          <div
            className={always("cps-flex-table__col").toggle(
              "directory-first-col-sm",
              "directory-first-col-lrg",
              this.props.sidePanelOpen
            )}
          >
            <span className="name-header-center">Name</span>
          </div>
          {!this.props.sidePanelOpen && (
            <>
              <div className="cps-flex-table__col">Type</div>
              <div className="cps-flex-table__col">Date created</div>
            </>
          )}
          <div className="cps-flex-table__col">
            <span
              className={toggle(
                "status-header-left",
                "status-header-center",
                this.props.sidePanelOpen
              )}
            >
              Status
            </span>
          </div>
          <div className="cps-flex-table__col action-col">
            <span className="action-header-center">Action</span>
          </div>
          <a
            onClick={this.handleFilterClick}
            className={always("cps-icon cps-icon-filter").maybe(
              "filter-green",
              this.state.isFilterOpen
            )}
          />
        </div>
        {this.state.isFilterOpen && (
          <div className="filter-row">
            <div
              className={always(
                "cps-flex-table__filter-col name-filter-col"
              ).maybe("name-filter-col-sm", this.props.sidePanelOpen)}
            >
              <div className="filter-arrow-container">
                <i className="cps-icon cps-icon-sm-caret-up caret-icon-name" />
                <i className="cps-icon cps-icon-sm-caret-down caret-icon-name" />
                <div
                  className="sort-div-up caret-icon-name"
                  onClick={() => this.props.handleSortClick("sortName", "asc")}
                />
                <div
                  className="sort-div-down caret-icon-name"
                  onClick={() => this.props.handleSortClick("sortName", "desc")}
                />
              </div>
              <input
                className="cps-form-control filter-input-name"
                value={this.props.filter.name}
                onChange={evt => this.props.handleFilterChange(evt, "name")}
                placeholder="Search"
              />
            </div>
            {!this.props.sidePanelOpen && (
              <>
                <div className="cps-flex-table__filter-col" />
                <div className="cps-flex-table__filter-col">
                  <div className="filter-arrow-container">
                    <i className="cps-icon cps-icon-sm-caret-up" />
                    <i className="cps-icon cps-icon-sm-caret-down" />
                    <div
                      className="sort-div-up"
                      onClick={() =>
                        this.props.handleSortClick("createdAt", "asc")
                      }
                    />
                    <div
                      className="sort-div-down"
                      onClick={() =>
                        this.props.handleSortClick("createdAt", "desc")
                      }
                    />
                  </div>
                  <input
                    className={always("cps-form-control filter-input").maybe(
                      "filter-input-sm",
                      this.props.sidePanelOpen
                    )}
                    value={this.props.filter.date}
                    onChange={evt => this.props.handleFilterChange(evt, "date")}
                    placeholder="Search"
                  />
                </div>
              </>
            )}
            <div className="cps-flex-table__filter-col">
              <div
                className={toggle(
                  "filter-arrow-container-status",
                  "filter-arrow-container",
                  this.props.sidePanelOpen
                )}
              >
                <i
                  className={always("cps-icon cps-icon-sm-caret-up").maybe(
                    "caret-icon-status",
                    !this.props.sidePanelOpen
                  )}
                />
                <i
                  className={always("cps-icon cps-icon-sm-caret-down").maybe(
                    "caret-icon-status",
                    !this.props.sidePanelOpen
                  )}
                />
                <div
                  className={always("sort-div-up").maybe(
                    "caret-icon-status",
                    !this.props.sidePanelOpen
                  )}
                  onClick={() => this.props.handleSortClick("status", "asc")}
                />
                <div
                  className={always("sort-div-down").maybe(
                    "caret-icon-status",
                    !this.props.sidePanelOpen
                  )}
                  onClick={() => this.props.handleSortClick("status", "desc")}
                />
              </div>
              <input
                className={always("cps-form-control")
                  .toggle(
                    "filter-input",
                    "filter-input-status",
                    this.props.sidePanelOpen
                  )
                  .maybe("filter-input-sm", this.props.sidePanelOpen)}
                value={this.props.filter.status}
                onChange={evt => this.props.handleFilterChange(evt, "status")}
                placeholder="Search"
              />
            </div>
            <div className="cps-flex-table__filter-col" />
          </div>
        )}
      </Scoped>
    );
  }
}

const css = k`
  .cps-flex-table__filter-col {
    display: flex;
    align-items: center;
    min-width: 120px;
    padding: 1.1rem 2.4rem;
    position: relative;
    flex: 8;
  }

  .directory-first-col-sm {
    min-width: 270px;
  }

  .directory-first-col-lrg {
    min-width: 625px;
  }

  .action-col {
    min-width: 125px;
  }

  .name-header-center {
    padding-left: 40px;
  }

  .status-header-center {
    padding-left: 24px;
  }

  .status-header-left {
    padding-left: 18px;
  }

  .action-header-center {
    padding-left: 8px;
  }

  .filter-row {
    display: flex;
    background-color: #52555a;
  }

  .filter-arrow-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0px;
  }

  .filter-arrow-container-status {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -10px;
  }

  .cps-icon-sm-caret-up {
    position: absolute;
    top: -20px;
  }

  .cps-icon-sm-caret-down {
    position: absolute;
    top: -4px;
  }

  .caret-icon-name {
    left: 10px;
  }

  .caret-icon-status {
    left: 24px;
  }

  .cps-flex-table__header {
    position: relative;
    display: flex;
    align-items: center;
  }

  .cps-icon-filter {
    padding-right: 24px;
  }

  .cps-icon-filter:hover {
    text-decoration: none;
  }

  .filter-input {
    width: auto;
  }

  .filter-input-name {
    width: auto;
    margin-left: 10px;
  }

  .filter-input-status {
    width: auto;
    margin-left: 24px;
  }

  .filter-input-sm {
    width: 80px;
  }

  .filter-green {
    color: #00BF4B;
  }

  .sort-div-up {
    top: -16px;
    width: 24px;
    height: 16px;
    cursor: pointer;
    position: absolute;
  }

  .sort-div-down {
    width: 24px;
    height: 16px;
    cursor: pointer;
    position: absolute;
  }

  .name-filter-col {
    padding-right: 42rem;
  }

  .name-filter-col-sm {
    padding-right: 16rem;
  }
`;
