import React, { Component } from "react";
import { bool, oneOf } from "prop-types";
import { Scoped, k, a } from "kremling";

export class Loader extends Component {
  static propTypes = {
    overlay: bool,
    color: oneOf(["dark", "light", "rainbow"]),
    size: oneOf(["small", "large"]),
  };

  static defaultProps = {
    color: "dark",
    size: "small",
  };

  colors = {
    dark: ["rgba(0,0,0,.3)", "rgba(0,0,0,.3)", "rgba(0,0,0,.3)"],
    light: [
      "rgba(255,255,255,.5",
      "rgba(255,255,255,.5",
      "rgba(255,255,255,.5",
    ],
    rainbow: ["#3399FF", "#F0D800", "#FF345E"],
  };

  render() {
    const { overlay, color, size } = this.props;
    const chosenColor = this.colors[color];
    let loaderSize = 50;

    if (size == "large") {
      loaderSize = 150;
    }

    return (
      <Scoped css={css}>
        <div
          className={a("loader-wrapper").m("loader-wrapper--overlay", overlay)}
        >
          <div
            style={{
              width: `${loaderSize / 10}rem`,
              height: `${loaderSize / 20}rem`,
            }}
          >
            <svg viewBox="0 0 100 50">
              <g transform="translate(25 25)">
                <circle
                  cx="0"
                  cy="0"
                  r="8"
                  fill={chosenColor[0]}
                  transform="scale(0.00355393 0.00355393)"
                >
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    begin="-0.3333333333333333s"
                    calcMode="spline"
                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                    values="0;1;0"
                    keyTimes="0;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </circle>
              </g>
              <g transform="translate(50 25)">
                <circle
                  cx="0"
                  cy="0"
                  r="8"
                  fill={chosenColor[1]}
                  transform="scale(0.315084 0.315084)"
                >
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    begin="-0.16666666666666666s"
                    calcMode="spline"
                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                    values="0;1;0"
                    keyTimes="0;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </circle>
              </g>
              <g transform="translate(75 25)">
                <circle
                  cx="0"
                  cy="0"
                  r="8"
                  fill={chosenColor[2]}
                  transform="scale(0.775459 0.775459)"
                >
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    begin="0s"
                    calcMode="spline"
                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                    values="0;1;0"
                    keyTimes="0;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </circle>
              </g>
            </svg>
          </div>
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader-wrapper > svg {
    width: 5rem;
    height: 5rem;
  }

  .loader-wrapper--overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
