

(function() {
  
  function _insertSvgIcons() {
    const svg = document.createRange().createContextualFragment(`<svg xmlns="http://www.w3.org/2000/svg" style="display: none;"><symbol id="af-refresh-nightly" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 24 24" xml:space="preserve"><path d="M14.236 4.053a8.123 8.123 0 00-1.259-.1 7.96 7.96 0 00-7.894 6.75c-.024.151-.024.302-.039.453l-.952-1.135a1.003 1.003 0 00-1.408-.122 1.002 1.002 0 00-.123 1.409l2.57 3.064c.118.14.274.227.443.285.037.017.078.021.117.034.041.009.077.031.12.035.017.002.034.002.051.002.038.005.073.023.112.024h.018c.261 0 .507-.1.694-.281l2.878-2.778a1.002 1.002 0 00.024-1.414 1.002 1.002 0 00-1.414-.025L7.03 11.359c.012-.115.012-.228.03-.343a5.998 5.998 0 016.865-4.988 5.966 5.966 0 015.062 5.916c0 .312-.025.631-.075.948a5.97 5.97 0 01-5.919 5.063c-.314 0-.633-.025-.946-.074a5.963 5.963 0 01-2.375-.922 1 1 0 10-1.105 1.668 8.071 8.071 0 004.425 1.329 7.962 7.962 0 007.894-6.75c.067-.421.101-.845.101-1.261a7.961 7.961 0 00-6.751-7.892zm-5.008 6.573z"/><path d="M11.463 15.583a4.481 4.481 0 01-1.754.267 5 5 0 101.695-8.746 4.502 4.502 0 01.059 8.479z"/></symbol></svg>`);
    if (document.body.childNodes[0]) {
      document.body.insertBefore(svg, document.body.childNodes[0]);  
    } else {
      document.body.appendChild(svg);
    }
  }
  if (/comp|inter|loaded/.test(document.readyState)) {
    _insertSvgIcons();
  } else {
    document.addEventListener('DOMContentLoaded', _insertSvgIcons);
  }
})();