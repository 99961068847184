import React from "react";
import { Scoped, k } from "kremling";
import { CprIcon } from "canopy-styleguide!sofe";

export default class TestModalSearch extends React.Component {
  render() {
    return (
      <Scoped css={css}>
        <div className="search-container">
          <CprIcon name="misc-magnifying-glass" />
          <input
            type="text"
            value={this.props.search}
            onChange={this.props.editSearch}
            className="cps-form-control search-input"
            placeholder="Quickly find a file or folder..."
          />
          {this.props.search && (
            <CprIcon
              name="close-circle-filled"
              className="search-clear"
              onClick={this.props.clearSearch}
            />
          )}
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .search-container {
    padding: 0.8rem 2.4rem;
    display: flex;
    align-items: center;
    width: 28%;
  }

  .search-input {
    border-color: var(--cps-color-chrome);
    background-color: var(--cps-color-chrome);
    padding-left: 14px;
  }

  .search-input:focus {
    box-shadow: none !important;
  }

  .search-clear {
    position: relative;
    margin-left: -24px;

  }
`;
