import React from "react";
import { Scoped, always, k } from "kremling";
import { capitalize } from "lodash";

export default class ManageTestsMoveItem extends React.Component {
  render() {
    const { item } = this.props;
    return (
      <Scoped css={css}>
        <div
          className={always("drag-container").maybe(
            "selected",
            item.isSelected
          )}
          key={item.id}
          onClick={() => this.props.selectMoveItem(item)}
        >
          <img
            src="https://cdn.canopytax.com/static/docs-ui/drag-dots.svg"
            className="drag-dots"
          />
          <i
            className={always("cps-icon").toggle(
              "cps-icon-pdf-file",
              "cps-icon-move",
              item.isTest
            )}
          />
          <div className="drag-text">
            <span className="drag-item-name">{item.name}</span>
            <span className="drag-status">{capitalize(item.status)}</span>
          </div>
          <i
            className="cps-icon cps-icon-sm-close"
            onClick={evt => {
              evt.stopPropagation();
              this.props.removeFromMoveList(item);
            }}
          />
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .drag-container {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    border: 1px solid #E9E9E9;
    border-radius: 1px;
    background-color: #FFFFFF;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
  }

  .selected {
    background-color: #ECF0F2;
  }

  .drag-dots {
    height: 40px;
  }

  .cps-icon-move, .cps-icon-pdf-file {
    color: #ff345e;
    margin: 8px;
  }

  .drag-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
  }

  .drag-status {
    color: #3399FF;
    font-size: 12px;
  }

  .cps-icon-sm-close {
    color: #fff;
    position: absolute;
    right: -32px;
    cursor: pointer;
  }

  .drag-item-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
