import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import TestMenu from "./test-menu.component.js";

export const floatingTestMenu = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: TestMenu,
});
