import React from "react";
import { Scoped, k } from "kremling";

function dragScroll(down) {
  return setInterval(() => {
    window.scrollTo(
      window.pageXOffset || document.documentElement.scrollLeft,
      (window.pageYOffset || document.documentElement.scrollTop) +
        (down ? 2 : -2)
    );
  }, 5);
}

export default class DocumentsDragLayer extends React.Component {
  constructor() {
    super();
    this.state = {
      mouseMove: e => {
        if (
          !this.state.coords ||
          e.pageX !== this.state.coords.x ||
          e.pageY !== this.state.coords.y
        ) {
          this.setState({
            coords: {
              x: e.clientX,
              y: e.clientY,
            },
          });
        }
      },
      docDragging: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.docDragging && !prevState.docDragging) {
      window.addEventListener("dragover", prevState.mouseMove);
      return { docDragging: true };
    } else if (prevState.docDragging && !nextProps.docDragging) {
      window.removeEventListener("dragover", prevState.mouseMove);
      return { coords: null, docDragging: false };
    }
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    const up =
      nextState.coords &&
      nextState.coords.y -
        (window.pageYOffset || document.documentElement.scrollTop) <
        300;
    const down =
      nextState.coords &&
      nextState.coords.y -
        (window.pageYOffset || document.documentElement.scrollTop) >
        window.innerHeight - 150;
    if (nextProps.docDragging && (up || down)) {
      if (!this.scrollInterval) {
        this.scrollInterval = dragScroll(down);
      }
    } else {
      clearInterval(this.scrollInterval);
      if (this.scrollInterval) this.scrollInterval = null;
    }
    return true;
  }
  componentWillUnmount() {
    window.removeEventListener("dragover", this.state.mouseMove);
  }
  render() {
    const { docDragging } = this.props;
    if (!docDragging || !this.state.coords) return null;
    return (
      <Scoped css={css}>
        <div className="drag-layer">
          <div
            style={{
              top:
                this.state.coords.y -
                (window.pageYOffset || document.documentElement.scrollTop),
              left: this.state.coords.x,
            }}
            className="cps-bg-info drag-box"
          >
            <div className="cps-bg-info corner-number">
              {this.props.selectedDocs.length}
            </div>
            Drag {this.props.selectedDocs.length} Files
          </div>
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .drag-box {
    border-radius: 5px;
    padding: 16px;
    position: fixed;
    color: white;
    text-align: center;
  }

  .corner-number {
    border-radius: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    color: white;
    position: absolute;
    top: -8px;
    right: -8px;
  }

  .drag-layer {
    position: fixed;
    pointer-events: none;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
